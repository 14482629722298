import { Interop_reactApi } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import react_google_recaptcha from "react-google-recaptcha";
import { comparePrimitives, uncurry2, createObj } from "./fable_modules/fable-library-js.4.17.0/Util.js";
import { toString as toString_1, Union, Record } from "./fable_modules/fable-library-js.4.17.0/Types.js";
import { array_type, anonRecord_type, obj_type, union_type, record_type, option_type, bool_type, string_type } from "./fable_modules/fable-library-js.4.17.0/Reflection.js";
import { Post_$reflection, DataServer_Short_ShortEvent_$reflection, Analytics_AssetMeta_$reflection, Analytics_AssetCount_$reflection, DataServer_Short_TopShortEntry_$reflection, Post, PostShortInfo, ShortEventPosition, User as User_1, AppleAuthRequest_$reflection, AppleAuthRequest, OAuthRegisterRequest_$reflection, OAuthRegisterRequest, OAuthAuthResponse_$reflection, RegisterRequest_$reflection, RegisterRequest, GenericResponse_$reflection } from "./Shared/ApiDataTypes.js";
import { FetchError, FetchError_$reflection } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.17.0/Result.js";
import { format, printf, toText, split } from "./fable_modules/fable-library-js.4.17.0/String.js";
import { mapIndexed, sortByDescending, map as map_1, item as item_2 } from "./fable_modules/fable-library-js.4.17.0/Array.js";
import { isMatch } from "./fable_modules/fable-library-js.4.17.0/RegExp.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Auto_generateBoxedEncoderCached_437914C6, Auto_generateBoxedEncoder_437914C6 } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { Auto_generateBoxedDecoderCached_Z6670B51, Auto_generateBoxedDecoder_Z6670B51 } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { unwrap, map, defaultArg, some } from "./fable_modules/fable-library-js.4.17.0/Option.js";
import { PromiseBuilder__Delay_62FBFDE1 as PromiseBuilder__Delay_62FBFDE1_1, PromiseBuilder__Run_212F1D4B as PromiseBuilder__Run_212F1D4B_1 } from "./fable_modules/Fable.Promise.2.0.0/Promise.fs.js";
import { promise as promise_1 } from "./fable_modules/Fable.Promise.2.0.0/PromiseImpl.fs.js";
import { Helper_fetch, Helper_withContentTypeJson, Helper_withProperties } from "./fable_modules/Thoth.Fetch.3.0.1/Fetch.fs.js";
import { Types_RequestProperties } from "./fable_modules/Fable.Fetch.2.1.0/Fetch.fs.js";
import { cons, ofArray, empty, singleton } from "./fable_modules/fable-library-js.4.17.0/List.js";
import { keyValueList } from "./fable_modules/fable-library-js.4.17.0/MapUtil.js";
import { toString } from "./fable_modules/Thoth.Json.6.0.0/Encode.fs.js";
import { fromString } from "./fable_modules/Thoth.Json.6.0.0/Decode.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { useReact_useEffectOnce_3A5B6456, useReact_useReducer_2B9E6EA0, useFeliz_React__React_useState_Static_1505 } from "./fable_modules/Feliz.2.7.0/React.fs.js";
import { MuiHelpers_createElement } from "./Feliz.MaterialUI/Mui.js";
import { Skeleton, CircularProgress, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Interop_reactApi as Interop_reactApi_1 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { append, empty as empty_1, singleton as singleton_1, delay, toList } from "./fable_modules/fable-library-js.4.17.0/Seq.js";
import { wrapTooltip, assetListEntryLoading, assetListEntry, tooltipHeader, assetListHolder, Image_userCircle, loadingIndicatorSmall, errorAlert } from "./ViewHelpers.js";
import react_apple_login from "react-apple-login";
import { GoogleLogin } from "@react-oauth/google";
import { toString as toString_2, utcNow, op_Subtraction, parse, toUniversalTime } from "./fable_modules/fable-library-js.4.17.0/Date.js";
import { days } from "./fable_modules/fable-library-js.4.17.0/TimeSpan.js";
import { Interop_reactApi as Interop_reactApi_2 } from "./fable_modules/Feliz.2.7.0/Interop.fs.js";
import { ResponsiveContainer, PieChart, Pie, Cell, Label, Legend } from "recharts";
import { StockRating__ToTextColorCode, StockRating, StockRating__ToColorCode } from "./Shared/Shared.js";
import { Dimensions_TextSmall } from "./Design.js";
import { post } from "./Content/Post.js";
import { toShortAmount, utcDateToLocalDate } from "./Utils.js";
import { fetchLatestReviews, fetchLatestInsiderTrades, drawReviews } from "./Home.js";
import { DataServerBaseUrl } from "./Session.js";

export function Register_Recaptcha_recaptchaContainer(props) {
    return Interop_reactApi.createElement(react_google_recaptcha, createObj(props));
}

class Register_Model extends Record {
    constructor(Username, Email, Password, PasswordRepeat, AcceptTerms, ValidUsername, ValidEmail, ValidPassword, Recaptched, Processing, ErrorMsg, Successful) {
        super();
        this.Username = Username;
        this.Email = Email;
        this.Password = Password;
        this.PasswordRepeat = PasswordRepeat;
        this.AcceptTerms = AcceptTerms;
        this.ValidUsername = ValidUsername;
        this.ValidEmail = ValidEmail;
        this.ValidPassword = ValidPassword;
        this.Recaptched = Recaptched;
        this.Processing = Processing;
        this.ErrorMsg = ErrorMsg;
        this.Successful = Successful;
    }
}

function Register_Model_$reflection() {
    return record_type("Investfora.Landing.Register.Model", [], Register_Model, () => [["Username", string_type], ["Email", string_type], ["Password", string_type], ["PasswordRepeat", string_type], ["AcceptTerms", bool_type], ["ValidUsername", bool_type], ["ValidEmail", bool_type], ["ValidPassword", bool_type], ["Recaptched", bool_type], ["Processing", bool_type], ["ErrorMsg", option_type(string_type)], ["Successful", bool_type]]);
}

class Register_Message extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpdateUsername", "UpdateEmail", "UpdatePassword", "UpdatePasswordRepeat", "ToggleAcceptTerms", "TryRegister", "SetRecaptched", "RecaptchaResponse", "RegisterResponse", "SetError"];
    }
}

function Register_Message_$reflection() {
    return union_type("Investfora.Landing.Register.Message", [], Register_Message, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [GenericResponse_$reflection(), FetchError_$reflection()], FSharpResult$2, () => [[["ResultValue", GenericResponse_$reflection()]], [["ErrorValue", FetchError_$reflection()]]])]], [["Item", option_type(string_type)]]]);
}

function Register_init() {
    return new Register_Model("", "", "", "", false, true, true, true, false, false, undefined, false);
}

export function Register_validateEmail(x) {
    const l = x.length > 5;
    const at = x.indexOf("@") >= 0;
    const dot = x.indexOf(".") >= 0;
    const splitted = split(x, ["@"], undefined, 0);
    if (splitted.length > 1) {
        if (((((l && at) && dot) && (item_2(1, splitted).length > 2)) && (item_2(0, splitted).length > 0)) && (item_2(1, splitted).indexOf(".") >= 0)) {
            return !(((x.indexOf(",") >= 0) ? true : (x.indexOf("\'") >= 0)) ? true : (x.indexOf("\\") >= 0));
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

function Register_validateCredentials(un, email, pw1, pw2) {
    const usernameLength = (un.length >= 3) && (un.length <= 16);
    const usernameChars = isMatch(/^[0-9A-Za-z]+$/gu, un);
    const email_1 = Register_validateEmail(email);
    const pwLength = pw1.length >= 8;
    const pwsMatch = pw1 === pw2;
    if (usernameLength) {
        if (usernameChars) {
            if (email_1) {
                if (pwLength) {
                    if (pwsMatch) {
                        return new FSharpResult$2(0, [undefined]);
                    }
                    else {
                        return new FSharpResult$2(1, ["Passwords don\'t match."]);
                    }
                }
                else {
                    return new FSharpResult$2(1, ["Password is too short."]);
                }
            }
            else {
                return new FSharpResult$2(1, ["Invalid email."]);
            }
        }
        else {
            return new FSharpResult$2(1, ["Username can only contain letters and numbers."]);
        }
    }
    else {
        return new FSharpResult$2(1, ["Username must be between 3 ang 16 characters."]);
    }
}

function Register_update(model, msg) {
    switch (msg.tag) {
        case 1:
            return new Register_Model(model.Username, msg.fields[0], model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 2:
            return new Register_Model(model.Username, model.Email, msg.fields[0], model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 3:
            return new Register_Model(model.Username, model.Email, model.Password, msg.fields[0], model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 4:
            return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, msg.fields[0], model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
        case 5: {
            const matchValue = Register_validateCredentials(model.Username, model.Email, model.Password, model.PasswordRepeat);
            if (matchValue.tag === 1) {
                return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, matchValue.fields[0], model.Successful);
            }
            else {
                return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, true, undefined, model.Successful);
            }
        }
        case 8: {
            const result = msg.fields[0];
            if (result.tag === 0) {
                const resp = result.fields[0];
                if (resp.Result === "success") {
                    return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, model.ErrorMsg, true);
                }
                else {
                    return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, resp.Message, model.Successful);
                }
            }
            else {
                return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, false, "Something went wrong, could not perform registration", model.Successful);
            }
        }
        case 6:
            return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, msg.fields[0], model.Processing, model.ErrorMsg, model.Successful);
        case 7: {
            const res = msg.fields[0];
            if (res.tag === 1) {
                return model;
            }
            else if (res.fields[0].Result === "success") {
                return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, true, model.Processing, model.ErrorMsg, model.Successful);
            }
            else {
                return model;
            }
        }
        case 9:
            return new Register_Model(model.Username, model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, msg.fields[0], model.Successful);
        default:
            return new Register_Model(msg.fields[0], model.Email, model.Password, model.PasswordRepeat, model.AcceptTerms, model.ValidUsername, model.ValidEmail, model.ValidPassword, model.Recaptched, model.Processing, model.ErrorMsg, model.Successful);
    }
}

function Register_validateRecaptcha(token, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        return ((body = Auto_generateBoxedEncoder_437914C6(string_type, undefined, undefined, undefined)(token), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
            let properties_2;
            try {
                const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/recaptcha", properties_3).then((_arg) => {
                    let response_1, decoder_1_1;
                    return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                        let matchValue;
                        return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                }))));
                return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
            }
            catch (exn) {
                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
            }
        })())))))).then((_arg_2) => {
            dispatch(new Register_Message(7, [_arg_2]));
            return Promise.resolve();
        });
    }));
    pr_1.then();
}

function Register_tryRegister(username, email, p1, p2, dispatch) {
    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let body, decoder, data_1, caseStrategy_3, extra_3;
        dispatch(new Register_Message(5, []));
        const matchValue = Register_validateCredentials(username, email, p1, p2);
        if (matchValue.tag === 0) {
            const req = new RegisterRequest(username, p1, email);
            return ((body = Auto_generateBoxedEncoder_437914C6(RegisterRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/sign-up", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue_1;
                            return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })())))))).then((_arg_2) => {
                dispatch(new Register_Message(8, [_arg_2]));
                return Promise.resolve();
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    pr_1.then();
}

export class Register_OAuthTarget extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Google", "Apple"];
    }
}

export function Register_OAuthTarget_$reflection() {
    return union_type("Investfora.Landing.Register.OAuthTarget", [], Register_OAuthTarget, () => [[["Item", OAuthAuthResponse_$reflection()]], [["Item", OAuthAuthResponse_$reflection()]]]);
}

export function Register_OAuthRegister(register_OAuthRegisterInputProps) {
    let elems_2, children, elems_1;
    const target = register_OAuthRegisterInputProps.target;
    const patternInput = useFeliz_React__React_useState_Static_1505("");
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(true);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setProcessing = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(undefined);
    const setErrMsg = patternInput_3[1];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(false);
    const terms = patternInput_4[0];
    const title = (target.tag === 1) ? "Apple" : "Google";
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        maxWidth: 340,
        gap: ((15 + "px ") + 0) + "px",
        color: "#FFFFFF",
    }], (elems_2 = [createElement("p", {
        style: {
            color: "#FFFFFF",
        },
        children: `Denne ${title}-kontoen og e-post adressen er ikke registrert på eksisterende aksje.io bruker. Fyll inn et brukernavn i feltet under for å komme i gang.`,
    }), MuiHelpers_createElement(TextField, [["autoFocus", true], ["variant", "outlined"], ["label", "Brukernavn"], ["autoComplete", "username"], ["onChange", (e) => {
        const u = e.target.value;
        const trimmed_1 = u.trim();
        patternInput[1](trimmed_1);
        patternInput_1[1](trimmed_1.length > 0);
    }], ["error", !patternInput_1[0]], ["style", {
        minWidth: 250 + "px",
        color: "#FFFFFF",
    }], ["inputProps", {
        style: {
            color: "#FFFFFF",
            fontSize: 1.3 + "rem",
        },
    }]]), createElement("span", {
        children: ["Kun brukernavnet er synlig for andre brukere på aksje.io."],
    }), MuiHelpers_createElement(TextField, [["id", "email-field"], ["variant", "outlined"], ["label", "E-post adresse"], ["type", "email"], ["disabled", true], ["value", (target.tag === 1) ? target.fields[0].Email : target.fields[0].Email], ["style", {
        minWidth: 250 + "px",
        color: "#FFFFFF",
    }], ["inputProps", {
        style: {
            color: "#FFFFFF",
            fontSize: 1.3 + "rem",
        },
    }]]), MuiHelpers_createElement(FormControlLabel, [["label", (children = ofArray(["Jeg har lest og aksepterer ", createElement("a", {
        style: {
            textDecoration: "underline",
            color: "#FFFFFF",
        },
        target: "_blank",
        href: "/terms-of-use",
        children: "brukervilkårene",
    })]), createElement("span", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))], ["control", MuiHelpers_createElement(Checkbox, [["style", {
        color: "#FFFFFF",
    }], ["checked", terms], ["onChange", (e_1) => {
        patternInput_4[1](e_1.target.checked);
    }], ["color", "primary"]])]]), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "center",
        minHeight: 40,
    }], (elems_1 = toList(delay(() => {
        let elems;
        return terms ? singleton_1(createElement("button", createObj(ofArray([["type", "submit"], ["className", "pill-button"], ["style", {
            width: 250,
            backgroundColor: "#FFFFFF",
            color: "var(--text-main)",
        }], ["onClick", (e_2) => {
            e_2.preventDefault();
            const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                let body, decoder, data_1, caseStrategy_3, extra_3;
                const trimmed = patternInput[0].trim();
                if (!((trimmed.length >= 3) && (trimmed.length <= 16))) {
                    setErrMsg("Brukernavnet må være mellom 3 og 16 tegn");
                    return Promise.resolve();
                }
                else {
                    setErrMsg(undefined);
                    setProcessing(true);
                    const req = new OAuthRegisterRequest((target.tag === 1) ? target.fields[0].Token : target.fields[0].Token, trimmed);
                    const requestPath = (target.tag === 1) ? "/api/apple-auth/register" : "/api/google-auth/register";
                    return ((body = Auto_generateBoxedEncoder_437914C6(OAuthRegisterRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(GenericResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                        let properties_2;
                        try {
                            const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                            const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(requestPath, properties_3).then((_arg) => {
                                let response_1, decoder_1_1;
                                return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(GenericResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                    let matchValue;
                                    return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                                })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                            }))));
                            return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                        }
                        catch (exn) {
                            return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                        }
                    })())))))).then((_arg_2) => {
                        const res = _arg_2;
                        if (res.tag === 0) {
                            const x_1 = res.fields[0];
                            if (x_1.Result === "success") {
                                window.location.reload(true);
                                return Promise.resolve();
                            }
                            else {
                                setErrMsg(x_1.Message);
                                setProcessing(false);
                                return Promise.resolve();
                            }
                        }
                        else {
                            setErrMsg("Kunne ikke registrere bruker. Vennligst prøv igjen senere");
                            setProcessing(false);
                            return Promise.resolve();
                        }
                    });
                }
            }));
            pr_1.then();
        }], (elems = toList(delay(() => (patternInput_2[0] ? singleton_1(MuiHelpers_createElement(CircularProgress, [["style", {
            width: 20,
            height: 20,
            color: "var(--text-main)",
        }]])) : singleton_1("Registrer deg")))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))) : empty_1();
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), errorAlert(patternInput_3[0], (_arg_3) => {
        setErrMsg(undefined);
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

function Register_registrationView(model, dispatch, handleGoogleToken, handleAppleToken) {
    let elems_12;
    return createElement("div", createObj(ofArray([["className", "register-form"], (elems_12 = toList(delay(() => append(singleton_1(createElement("span", {
        style: {
            fontSize: 1.4 + "rem",
            color: "#FFFFFF",
            fontWeight: "bold",
            textAlign: "center",
        },
        children: "Registrer deg på Aksje.io",
    })), delay(() => {
        let elems_3, elems_2, props_4;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 30,
            gap: ((20 + "px ") + 0) + "px",
        }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
            padding: 2,
        }], (elems_2 = [(props_4 = [["clientId", "io.aksje.www"], ["redirectURI", `https://${window.location.host}/apple-auth`], ["usePopup", true], ["callback", (res) => {
            console.log(some("apple login callback"));
            console.log(some(res));
            handleAppleToken(res);
        }], ["scope", "email"], ["responseMode", "query"], ["render", (renderProps) => {
            let elems_1, elems;
            return createElement("button", createObj(ofArray([["onClick", (_arg) => {
                renderProps.onClick();
            }], ["style", {
                backgroundColor: "white",
                color: "#202124",
                fontSize: 15 + "px",
                minWidth: 190,
                maxWidth: 400,
                borderRadius: 25,
                height: 40,
                cursor: "pointer",
            }], (elems_1 = [createElement("div", createObj(ofArray([["style", {
                gap: ((0 + "px ") + 5) + "px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                padding: ((0 + "px ") + 5) + "px",
            }], (elems = [createElement("i", {
                style: {
                    fontSize: 25 + "px",
                },
                className: "fab fa-apple px-2",
            }), "Logg inn med Apple"], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
        }]], Interop_reactApi.createElement(react_apple_login, createObj(props_4)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])]))), Interop_reactApi.createElement(GoogleLogin, {
            size: "large",
            shape: "pill",
            theme: "filled_white",
            onSuccess: (x_10) => {
                console.log(some(`onSuccess: ${x_10}`));
                console.log(some(`onSuccess: ${toString_1(x_10)}`));
                console.log(some(`credential: ${x_10.credential}`));
                handleGoogleToken(x_10.credential);
            },
            onError: (x_12) => {
                console.log(some(`onError: ${x_12}`));
            },
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))), delay(() => {
            let elems_5, elems_4;
            return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                display: "flex",
                justifyContent: "space-around",
                gap: ((5 + "px ") + 5) + "px",
                alignItems: "center",
                width: 100 + "%",
                maxWidth: 350,
                margin: ((30 + "px ") + 0) + "px",
            }], (elems_5 = [createElement("div", {
                style: {
                    flexGrow: 2,
                    height: 2,
                    backgroundColor: "#FFFFFF",
                },
            }), createElement("div", createObj(ofArray([["style", {
                flexGrow: 1,
                textAlign: "center",
                color: "#FFFFFF",
            }], (elems_4 = [createElement("span", {
                style: {
                    fontSize: 1.3 + "rem",
                },
                children: "eller",
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
                style: {
                    flexGrow: 2,
                    height: 2,
                    backgroundColor: "#FFFFFF",
                },
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])))), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["id", "username-field"], ["variant", "outlined"], ["label", "Brukernavn"], ["autoComplete", "username"], ["onChange", (e) => {
                dispatch(new Register_Message(0, [e.target.value]));
            }], ["onKeyDown", (e_1) => {
                if (e_1.code === "Enter") {
                    window.document.getElementById("email-field").focus();
                }
            }], ["error", !model.ValidUsername], ["size", "small"], ["style", {
                marginTop: 10,
                minWidth: 250,
            }], ["inputProps", {
                style: {
                    fontSize: 1.3 + "rem",
                },
            }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["id", "email-field"], ["variant", "outlined"], ["label", "E-post"], ["type", "email"], ["autoComplete", "email"], ["onChange", (e_2) => {
                dispatch(new Register_Message(1, [e_2.target.value]));
            }], ["onKeyDown", (e_3) => {
                if (e_3.code === "Enter") {
                    window.document.getElementById("password1-field").focus();
                }
            }], ["error", !model.ValidEmail], ["size", "small"], ["style", {
                marginTop: 10,
                minWidth: 250,
            }], ["inputProps", {
                style: {
                    fontSize: 1.3 + "rem",
                },
            }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["id", "password1-field"], ["variant", "outlined"], ["label", "Passord"], ["type", "password"], ["autoComplete", "new-password"], ["onChange", (e_4) => {
                dispatch(new Register_Message(2, [e_4.target.value]));
            }], ["onKeyDown", (e_5) => {
                if (e_5.code === "Enter") {
                    window.document.getElementById("password2-field").focus();
                }
            }], ["error", !model.ValidPassword], ["size", "small"], ["style", {
                marginTop: 10,
                minWidth: 250,
            }], ["inputProps", {
                style: {
                    fontSize: 1.3 + "rem",
                },
            }]])), delay(() => append(singleton_1(MuiHelpers_createElement(TextField, [["id", "password2-field"], ["variant", "outlined"], ["label", "Gjenta passord"], ["type", "password"], ["onChange", (e_6) => {
                dispatch(new Register_Message(3, [e_6.target.value]));
            }], ["error", !model.ValidPassword], ["size", "small"], ["style", {
                marginTop: 10,
                marginBottom: 10,
                minWidth: 250,
            }], ["inputProps", {
                style: {
                    fontSize: 1.3 + "rem",
                },
            }]])), delay(() => {
                let elems_7;
                return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                    height: 40,
                }], (elems_7 = toList(delay(() => {
                    let elems_6;
                    return ((((model.Username.length > 0) && (model.Email.length > 0)) && (model.Password.length > 0)) && (model.PasswordRepeat.length > 0)) ? singleton_1(MuiHelpers_createElement(FormControlLabel, [["label", createElement("span", createObj(ofArray([["style", {
                        color: "#FFFFFF",
                    }], (elems_6 = ["Jeg har lest og aksepterer ", createElement("a", {
                        style: {
                            textDecoration: "underline",
                            color: "#FFFFFF",
                        },
                        target: "_blank",
                        href: "/terms-of-use",
                        children: "brukervilkårene",
                    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])])))], ["control", MuiHelpers_createElement(Checkbox, [["style", {
                        color: "var(--text-main)",
                    }], ["checked", model.AcceptTerms], ["onChange", (e_7) => {
                        dispatch(new Register_Message(4, [e_7.target.checked]));
                    }], ["color", "primary"], ["size", "small"]])]])) : empty_1();
                })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])])))), delay(() => {
                    let elems_8;
                    return append(!model.AcceptTerms ? singleton_1(createElement("div", createObj(ofArray([["style", {
                        textAlign: "center",
                        color: "#FFFFFF",
                    }], (elems_8 = [createElement("span", {
                        children: ["Allerede registrert?"],
                    }), createElement("a", {
                        style: {
                            textDecoration: "underline",
                            marginLeft: 10,
                            color: "#FFFFFF",
                        },
                        href: "/sign-in",
                        children: "Logg inn",
                    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_8))])])))) : empty_1(), delay(() => {
                        let elems_9;
                        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
                            marginTop: 15,
                            display: "flex",
                            justifyContent: "center",
                        }], (elems_9 = toList(delay(() => ((model.AcceptTerms && !model.Recaptched) ? singleton_1(Register_Recaptcha_recaptchaContainer([["sitekey", "6Lfptv8mAAAAAOjwVLP08rOqUO0zDXpcQ4kvDCul"], ["onChange", (s) => {
                            if (s == null) {
                                dispatch(new Register_Message(6, [false]));
                            }
                            else {
                                Register_validateRecaptcha(s, dispatch);
                            }
                        }], ["theme", "dark"], ["onExpired", () => {
                            dispatch(new Register_Message(6, [false]));
                        }]])) : empty_1()))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))])])))), delay(() => {
                            let elems_11, elems_10;
                            return append((model.AcceptTerms && model.Recaptched) ? singleton_1(createElement("div", createObj(ofArray([["style", {
                                minHeight: 50,
                                marginTop: 10,
                                minWidth: 250,
                                textAlign: "center",
                            }], (elems_11 = [createElement("button", createObj(ofArray([["className", "pill-button"], ["style", {
                                minWidth: 250 + "px",
                                backgroundColor: "#FFFFFF",
                                color: "var(--text-main)",
                            }], ["onClick", (_arg_2) => {
                                Register_tryRegister(model.Username, model.Email, model.Password, model.PasswordRepeat, dispatch);
                            }], (elems_10 = toList(delay(() => (model.Processing ? singleton_1(loadingIndicatorSmall()) : singleton_1("Registrer deg")))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_10))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_11))])])))) : empty_1(), delay(() => singleton_1(errorAlert(model.ErrorMsg, (_arg_3) => {
                                dispatch(new Register_Message(9, [undefined]));
                            }))));
                        }));
                    }));
                }));
            }))))))))));
        }));
    })))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_12))])])));
}

function Register_drawBanned(until, banReason) {
    let elems_2;
    return createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
        maxWidth: 340,
        padding: 5,
        display: "flex",
        flexDirection: "column",
        gap: ((20 + "px ") + 0) + "px",
        fontSize: 1.3 + "rem",
    }], (elems_2 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(ofArray([["style", {
            padding: 5,
            textAlign: "center",
            borderRadius: 5,
            fontWeight: "bold",
            color: "#FFFFFF",
        }], (elems = [createElement("span", {
            children: ["Denne brukeren er utestengt fra Aksje.io."],
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))), delay(() => {
            let matchValue, u, elems_1;
            return append((matchValue = until, (matchValue != null) ? ((u = matchValue, singleton_1(createElement("div", createObj(ofArray([["style", {
                textAlign: "center",
                color: "#FFFFFF",
            }], (elems_1 = [createElement("span", {
                children: [u],
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))))) : (empty_1())), delay(() => singleton_1(createElement("p", {
                style: {
                    marginTop: 10,
                    textAlign: "center",
                    color: "#FFFFFF",
                },
                children: banReason,
            }))));
        }));
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

export function Register_Register() {
    let elems, value_22;
    const patternInput = useReact_useReducer_2B9E6EA0(Register_update, Register_init());
    const model_1 = patternInput[0];
    const dispatch = patternInput[1];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const appleRegisterForm = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(undefined);
    const googleRegisterForm = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(undefined);
    const setBanned = patternInput_3[1];
    const banned = patternInput_3[0];
    if (!model_1.Successful) {
        if (banned == null) {
            if (googleRegisterForm != null) {
                const grf = googleRegisterForm;
                return createElement(Register_OAuthRegister, {
                    target: new Register_OAuthTarget(0, [grf]),
                });
            }
            else if (appleRegisterForm != null) {
                const arf = appleRegisterForm;
                return createElement(Register_OAuthRegister, {
                    target: new Register_OAuthTarget(1, [arf]),
                });
            }
            else {
                return Register_registrationView(model_1, dispatch, (x_2) => {
                    const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let item_1, body_3, decoder_3, data_5, caseStrategy_12, extra_12;
                        return ((item_1 = {
                            Token: x_2,
                        }, (body_3 = Auto_generateBoxedEncoder_437914C6(anonRecord_type(["Token", string_type]), undefined, undefined, undefined)(item_1), (decoder_3 = Auto_generateBoxedDecoder_Z6670B51(OAuthAuthResponse_$reflection(), undefined, undefined), (data_5 = some(body_3), (caseStrategy_12 = undefined, (extra_12 = undefined, (() => {
                            let properties_6;
                            try {
                                const properties_3_1 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_6 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_5, empty()), 0)])]), defaultArg(map((data_1_2) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_12, extra_12)(data_1_2))]), properties_6), data_5), properties_6)));
                                const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/google-auth/authenticate", properties_3_1).then((_arg_3) => {
                                    let response_3, decoder_1_2;
                                    return ((response_3 = _arg_3, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_Z6670B51(OAuthAuthResponse_$reflection(), unwrap(caseStrategy_12), unwrap(extra_12))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_4) => {
                                        let matchValue_3;
                                        return Promise.resolve((matchValue_3 = fromString(uncurry2(decoder_1_2), _arg_4), (matchValue_3.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_3.fields[0]])])) : (new FSharpResult$2(0, [matchValue_3.fields[0]]))));
                                    })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_3])])))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                                }))));
                                return pr_2.then(void 0, ((arg_3) => (new FSharpResult$2(1, [new FetchError(3, [arg_3])]))));
                            }
                            catch (exn_1) {
                                return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_1])])))));
                            }
                        })()))))))).then((_arg_5) => {
                            let matchValue_4, x_4, numDays_1, arg_4;
                            const res_1 = _arg_5;
                            if (res_1.tag === 0) {
                                const r_1 = res_1.fields[0];
                                switch (r_1.Result.Result) {
                                    case "success": {
                                        window.location.reload(true);
                                        return Promise.resolve();
                                    }
                                    case "not-exist": {
                                        patternInput_2[1](r_1);
                                        return Promise.resolve();
                                    }
                                    case "banned": {
                                        setBanned([(matchValue_4 = r_1.Result.BanUntil, (matchValue_4 != null) ? ((x_4 = toUniversalTime(parse(matchValue_4)), (numDays_1 = (days(op_Subtraction(x_4, utcNow())) | 0), (arg_4 = toString_2(x_4, "dd/MM/yyyy"), toText(printf("Til %s (%i dager igjen)"))(arg_4)(numDays_1))))) : undefined), r_1.Result.Message]);
                                        return Promise.resolve();
                                    }
                                    default: {
                                        dispatch(new Register_Message(9, [r_1.Result.Message]));
                                        return Promise.resolve();
                                    }
                                }
                            }
                            else {
                                dispatch(new Register_Message(9, ["Kunne ikke registrere bruker deg inn. Vennligst prøv igjen senere."]));
                                return Promise.resolve();
                            }
                        });
                    }));
                    pr_3.then();
                }, (callback) => {
                    const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
                        let matchValue, body, decoder, data_1, caseStrategy_3, extra_3;
                        if (callback.error == null) {
                            const req = new AppleAuthRequest(callback.authorization.code, callback.authorization.id_token, (matchValue = callback.user, (matchValue != null) ? matchValue.email : undefined));
                            return ((body = Auto_generateBoxedEncoder_437914C6(AppleAuthRequest_$reflection(), undefined, undefined, undefined)(req), (decoder = Auto_generateBoxedDecoder_Z6670B51(OAuthAuthResponse_$reflection(), undefined, undefined), (data_1 = some(body), (caseStrategy_3 = undefined, (extra_3 = undefined, (() => {
                                let properties_2;
                                try {
                                    const properties_3 = Helper_withProperties(singleton(new Types_RequestProperties(3, ["cors"])), (properties_2 = ofArray([new Types_RequestProperties(0, ["POST"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_3, extra_3)(data_1_1))]), properties_2), data_1), properties_2)));
                                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/apple-auth/authenticate", properties_3).then((_arg) => {
                                        let response_1, decoder_1_1;
                                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(OAuthAuthResponse_$reflection(), unwrap(caseStrategy_3), unwrap(extra_3))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                                            let matchValue_1;
                                            return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                                    }))));
                                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                                }
                                catch (exn) {
                                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                                }
                            })())))))).then((_arg_2) => {
                                let matchValue_2, x_1, numDays, arg_1;
                                const res = _arg_2;
                                if (res.tag === 0) {
                                    const r = res.fields[0];
                                    switch (r.Result.Result) {
                                        case "success": {
                                            window.location.reload(true);
                                            return Promise.resolve();
                                        }
                                        case "not-exist": {
                                            patternInput_1[1](r);
                                            return Promise.resolve();
                                        }
                                        case "banned": {
                                            setBanned([(matchValue_2 = r.Result.BanUntil, (matchValue_2 != null) ? ((x_1 = toUniversalTime(parse(matchValue_2)), (numDays = (days(op_Subtraction(x_1, utcNow())) | 0), (arg_1 = toString_2(x_1, "dd/MM/yyyy"), toText(printf("Til %s (%i dager igjen)"))(arg_1)(numDays))))) : undefined), r.Result.Message]);
                                            return Promise.resolve();
                                        }
                                        default: {
                                            dispatch(new Register_Message(9, [r.Result.Message]));
                                            return Promise.resolve();
                                        }
                                    }
                                }
                                else {
                                    dispatch(new Register_Message(9, ["Kunne ikke logge deg inn. Vennligst prøv igjen senere."]));
                                    return Promise.resolve();
                                }
                            });
                        }
                        else {
                            return Promise.resolve();
                        }
                    }));
                    pr_1.then();
                });
            }
        }
        else {
            return Register_drawBanned(banned[0], banned[1]);
        }
    }
    else {
        return createElement("div", createObj(ofArray([["className", "login-form"], ["style", {
            textAlign: "center",
        }], (elems = [createElement("span", {
            style: {
                fontSize: 1.7 + "rem",
                marginBottom: 25,
                padding: 10 + "px",
            },
            children: "Brukerkonto opprettet!",
        }), createElement("br", {}), createElement("p", createObj(ofArray([["style", {
            maxWidth: 500,
            textAlign: "center",
        }], (value_22 = "Du kan nå logge inn med din nye bruker på Aksje.io. Du må bekrefte din e-post adresse før du kan publisere innhold til Aksje.io. En e-post med instruksjoner om hvordan du kan bekrefte din e-post adresse er sendt til e-post adressen du registrerte brukeren med.", ["children", value_22])]))), createElement("a", {
            style: {
                color: "#FFFFFF",
                backgroundColor: "var(--text-main)",
                marginTop: 20,
            },
            className: "pill-button",
            href: "/sign-in",
            children: "Logg inn",
        })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])));
    }
}

const demoData = [{
    name: "Kjøp",
    value: 42,
}, {
    name: "Svakt kjøp",
    value: 21,
}, {
    name: "Hold",
    value: 29,
}, {
    name: "Svakt salg",
    value: 27,
}, {
    name: "Selg",
    value: 19,
}];

function demoGraph() {
    let elems_2, properties_10, properties_9, elements_1, properties_8, elements, properties_3, properties_4, properties_5, properties_6, properties_7;
    return createElement("div", createObj(ofArray([["style", {
        height: 300,
        width: 300,
        fontSize: 0.7 + "rem",
        fontWeight: "bold",
        textTransform: "uppercase",
    }], (elems_2 = [(properties_10 = singleton(["children", (properties_9 = ofArray([["margin", {
        top: defaultArg(10, 0),
        right: defaultArg(0, 0),
        left: defaultArg(0, 0),
        bottom: defaultArg(30, 0),
    }], (elements_1 = ofArray([Interop_reactApi_2.createElement(Legend, {}), Interop_reactApi_2.createElement(Label, {}), (properties_8 = ofArray([["data", demoData], ["dataKey", "value"], (elements = ofArray([(properties_3 = ofArray([["key", "Kjøp"], ["fill", StockRating__ToColorCode(new StockRating(0, []))]]), Interop_reactApi_2.createElement(Cell, createObj(properties_3))), (properties_4 = ofArray([["key", "Svakt kjøp"], ["fill", StockRating__ToColorCode(new StockRating(1, []))]]), Interop_reactApi_2.createElement(Cell, createObj(properties_4))), (properties_5 = ofArray([["key", "Hold"], ["fill", StockRating__ToColorCode(new StockRating(2, []))]]), Interop_reactApi_2.createElement(Cell, createObj(properties_5))), (properties_6 = ofArray([["key", "Svakt salg"], ["fill", StockRating__ToColorCode(new StockRating(3, []))]]), Interop_reactApi_2.createElement(Cell, createObj(properties_6))), (properties_7 = ofArray([["key", "Selg"], ["fill", StockRating__ToColorCode(new StockRating(4, []))]]), Interop_reactApi_2.createElement(Cell, createObj(properties_7)))]), ["children", ["children", Interop_reactApi_1.Children.toArray(Array.from(elements))]])]), Interop_reactApi_2.createElement(Pie, createObj(properties_8)))]), ["children", Interop_reactApi_1.Children.toArray(Array.from(elements_1))])]), Interop_reactApi_2.createElement(PieChart, createObj(properties_9)))]), Interop_reactApi_2.createElement(ResponsiveContainer, createObj(properties_10)))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

export function demoPost(rating, votes, tp) {
    let elems_14, elems, elems_13, elems_10, elems_6, elems_3, elems_1, elems_2, elems_5, elems_4, elems_8, children, elems_7, elems_9, elems_12, elems_11;
    return createElement("div", createObj(ofArray([["className", "post"], ["key", "demo-post"], ["style", {
        minWidth: 350,
    }], (elems_14 = [createElement("div", createObj(ofArray([["className", "post-header"], (elems = [createElement("span", {
        style: {
            fontSize: 1.225 + "rem",
        },
        children: "Din favorittaksje",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "post-content-holder"], (elems_13 = [createElement("div", createObj(ofArray([["className", "post-content"], (elems_10 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
    }], (elems_6 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flex: 0.5,
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        marginRight: 10,
    }], (elems_1 = [Image_userCircle(1, 50, false, undefined)], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
    }], (elems_2 = [createElement("span", {
        style: {
            color: "var(--bg-dark)",
            fontWeight: 500,
        },
        children: "Bruker",
    }), createElement("span", {
        style: {
            fontSize: Dimensions_TextSmall,
        },
        children: "~10 minutter siden",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flex: 0.5,
        alignItems: "center",
        padding: 3,
        justifyContent: "flex-end",
    }], (elems_5 = [createElement("div", createObj(ofArray([["style", {
        backgroundColor: StockRating__ToColorCode(rating),
        padding: 10,
        width: 100,
        borderRadius: 5,
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
        letterSpacing: "0.35px",
        userSelect: "none",
    }], (elems_4 = [createElement("span", {
        style: {
            color: StockRating__ToTextColorCode(rating),
        },
        children: toString_1(rating),
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_6))])]))), createElement("div", createObj(ofArray([["className", "post-review-info"], (elems_8 = [(children = ofArray([createElement("span", {
        style: {
            marginRight: 5,
            color: "#DC143C",
        },
        className: "fas fa-bullseye",
    }), createElement("span", createObj(ofArray([["style", {
        marginRight: 15,
    }], (elems_7 = ["Kursmål ", createElement("b", {
        children: [tp],
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_7))])])))]), createElement("div", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_8))])]))), createElement("div", createObj(ofArray([["style", {}], (elems_9 = [createElement("p", {
        style: {
            color: "var(--text-main)",
            fontSize: 1 + "rem",
            margin: 0,
            padding: 0,
        },
        children: "...",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_9))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_10))])]))), createElement("div", createObj(ofArray([["className", "post-rating-holder"], (elems_12 = [createElement("div", createObj(ofArray([["className", "post-rating"], (elems_11 = [createElement("span", {
        style: {
            color: (votes > 0) ? StockRating__ToColorCode(new StockRating(0, [])) : StockRating__ToColorCode(new StockRating(4, [])),
            paddingRight: 20,
        },
        children: votes,
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_11))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_12))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_13))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_14))])])));
}

function insiderTrades(x) {
    let elems_5, elems, children, elems_1, elems_4, value_17, value_18, elems_3, value_20, children_2, elems_2;
    return createElement("div", createObj(ofArray([["className", "landing-favorites-holder"], (elems_5 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
    }], (elems = [(children = ofArray(["Enkel og informativ oversikt over ", createElement("strong", {
        children: ["innsidehandler"],
    })]), createElement("h2", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "landing-favorites-img"], (elems_1 = toList(delay(() => {
        let xs_1;
        if (x != null) {
            const y = x;
            return singleton_1((xs_1 = map_1((i) => post(i, (value_13) => {
            }), (y.length > 2) ? y.slice(0, 1 + 1) : y), react.createElement(react.Fragment, {}, ...xs_1)));
        }
        else {
            return singleton_1(MuiHelpers_createElement(Skeleton, [["className", "post"]]));
        }
    })), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "landing-favorites-text"], (elems_4 = [(value_17 = "Aksje.io bruker kunstig intelligens (AI) for å hente ut strukturert informasjon om innsidehandler på Oslo børs og presenterer det for deg på en enkel og oversiktlig måte.", createElement("p", {
        children: [value_17],
    })), (value_18 = "Få info om antall aksjer, snittpris, innsider m.m for innsidehandler gjort i dine favorittaksjer. Aksje.io genererer rapporter og statistikk rundt innsidehandler for aksjer som hjelper deg få bedre innsikt og gjøre bedre investeringer.", createElement("p", {
        children: [value_18],
    })), createElement("div", createObj(ofArray([["style", {}], (elems_3 = [(value_20 = "Motta push-varslinger ved nye innsidehandler og short-oppdateringer med aksje.io appen. Last ned fra Apple App Store.", createElement("p", {
        children: [value_20],
    })), (children_2 = singleton(createElement("a", createObj(ofArray([["href", "https://apps.apple.com/app/aksje-io/id6448765436"], (elems_2 = [createElement("img", {
        src: "/images/app_store_download.svg",
        target: "_blank",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))), createElement("p", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_5))])])));
}

function header() {
    let elems_4, elems, children, elems_3, elems_1, elems_2, value_27, value_28;
    return createElement("div", createObj(ofArray([["className", "landing-header-holder"], (elems_4 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
    }], (elems = [(children = ofArray([createElement("strong", {
        children: ["Oppdag og del "],
    }), "investeringsidéer"]), createElement("h2", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    }], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }], (elems_1 = [demoGraph(), createElement("span", {
        style: {
            fontSize: 0.6 + "rem",
            fontStyle: "italic",
        },
        children: "* Graf kun for demonstasjon.",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "landing-header-text"], (elems_2 = [(value_27 = "Finn inspirasjon for dine neste investeringer fra analyser og innlegg publisert av brukere på Aksje.io.", createElement("p", {
        children: [value_27],
    })), (value_28 = "Del dine egne tanker og analyser under dine favorittaksjer. Delta i diskusjoner og hjelp hverandre gjøre bedre investeringsvalg.", createElement("p", {
        children: [value_28],
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_4))])])));
}

function drawMostViewed(x) {
    return assetListHolder(toList(delay(() => append(singleton_1(tooltipHeader("Populære aksjer", "Aksjer med mest trafikk siste 14 dager.")), delay(() => {
        let xs;
        const matchValue = x;
        return (matchValue != null) ? singleton_1((xs = map_1((y) => {
            let matchValue_1;
            return assetListEntry(y.Ticker, (matchValue_1 = y.Name, (matchValue_1 != null) ? matchValue_1 : y.Ticker), (y.Market.toLocaleUpperCase() === "CRYPTO") ? (`/crypto/${y.Ticker}`) : (`/stock/${y.Market}/${y.Ticker}`));
        }, matchValue), react.createElement(react.Fragment, {}, ...xs))) : append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => singleton_1(assetListEntryLoading())))))))));
    })))));
}

function drawMostFavorite(x) {
    return assetListHolder(toList(delay(() => append(singleton_1(tooltipHeader("Brukeres favorittaksjer", "Aksjer flest brukere av Aksje.io har markert som favoritt")), delay(() => {
        let xs;
        const matchValue = x;
        return (matchValue != null) ? singleton_1((xs = map_1((y_2) => assetListEntry(y_2.AssetTicker, y_2.Name, `/stock/${y_2.MarketTicker}/${y_2.AssetTicker}`), sortByDescending((y) => y.Num, matchValue, {
            Compare: comparePrimitives,
        })), react.createElement(react.Fragment, {}, ...xs))) : append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => singleton_1(assetListEntryLoading())))))))));
    })))));
}

function drawMostShorted(x) {
    return assetListHolder(toList(delay(() => append(singleton_1(tooltipHeader("Aksjer med størst % andel short", "Basert på posisjoner større eller lik 2% av utestående aksjer")), delay(() => {
        let xs_7;
        const matchValue = x;
        return (matchValue != null) ? singleton_1((xs_7 = map_1((y_2) => {
            let elems_3, elems, p, elems_1, elems_2;
            const link = `/stock/${y_2.Market}/${y_2.Ticker}`;
            return createElement("div", createObj(ofArray([["className", "preview-listing-holder"], (elems_3 = [createElement("div", createObj(ofArray([["style", {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 5,
                whiteSpace: "nowrap",
            }], (elems = [createElement("span", {
                style: {
                    fontWeight: "bold",
                },
                children: (p = (y_2.Percentage / 100), format('{0:' + "0.00%" + '}', p)),
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "preview-listing-name"], (elems_1 = [createElement("a", {
                href: link,
                children: y_2.Name,
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "preview-listing-ticker"], (elems_2 = [createElement("a", {
                href: link,
                children: y_2.Ticker,
            })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])));
        }, sortByDescending((y) => y.Percentage, matchValue, {
            Compare: comparePrimitives,
        })), react.createElement(react.Fragment, {}, ...xs_7))) : append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => singleton_1(assetListEntryLoading())))))))));
    })))));
}

function drawExplore(x) {
    return assetListHolder(toList(delay(() => append(singleton_1(tooltipHeader("Utforsk aksjer", "En tilfeldig samling aksjer registrert på Aksje.io")), delay(() => {
        let xs;
        const matchValue = x;
        return (matchValue != null) ? singleton_1((xs = map_1((y) => assetListEntry(y.Ticker, y.Name, `/stock/${y.Market}/${y.Ticker}`), matchValue), react.createElement(react.Fragment, {}, ...xs))) : append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => append(singleton_1(assetListEntryLoading()), delay(() => singleton_1(assetListEntryLoading())))))))));
    })))));
}

function signup() {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["style", {
        flexGrow: 1,
    }], ["className", "landing-signup"], (elems_1 = [createElement("div", createObj(ofArray([["className", "landing-signup-box"], (elems = [createElement(Register_Register, null)], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
}

const demoShort = new Post(700, "short-event", "", 0, new User_1(-1, "", false), 0, 0, "2023-05-03", 0, false, [], 0, false, undefined, undefined, undefined, {
    Market: "OSE",
    Name: "Nordic Semiconductor",
    Ticker: "NOD",
    Type: 1,
}, undefined, new PostShortInfo(443, 392, 7577259, 8566131, [new ShortEventPosition(9, "WorldQuant LLC", 1602364, 1602364), new ShortEventPosition(18, "JPMorgan Asset Management (UK) Ltd", 1489762, 1489762), new ShortEventPosition(19, "Sylebra Capital Limited", 3269501, 3269501), new ShortEventPosition(28, "Canada Pension Plan Investment Board", 1215632, 1215632), new ShortEventPosition(30, "BlackRock Investment Management (UK) Limited", 0, 988872)]), undefined);

function favorites() {
    let elems_3, elems, children, elems_1, elems_2, value_17, value_19;
    return createElement("div", createObj(ofArray([["className", "landing-favorites-holder"], (elems_3 = [createElement("div", createObj(ofArray([["style", {
        width: 100 + "%",
    }], (elems = [(children = ofArray(["Analyser, innlegg, innsidehandler og short-oppdateringer for ", createElement("strong", {
        children: ["dine favorittaksjer"],
    }), " gjennom din ", createElement("strong", {
        children: ["feed"],
    })]), createElement("h2", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "landing-favorites-img"], (elems_1 = [demoPost(new StockRating(0, []), 4, "NOK 158.70"), post(demoShort, (value_13) => {
    }), demoPost(new StockRating(3, []), 8, "NOK 69.12")], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "landing-favorites-text"], (elems_2 = [createElement("p", createObj(singleton((value_17 = "Marker aksjer som favoritt og nye analyser, innlegg, innsidehandler og short-oppdateringer publisert på Aksje.io vil dukke opp i din feed.", ["children", value_17])))), createElement("p", createObj(singleton((value_19 = "Vil du ha oppdateringer på 2 eller 20 aksjer? Det bestemmer du selv. Bygg din favorittliste slik som du selv ønsker.", ["children", value_19]))))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_3))])])));
}

function joinNow() {
    let elems_2, elems, elems_1;
    return createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 5 + "vw",
        gap: ((5 + "vh") + " ") + (5 + "vh"),
    }], (elems_2 = [createElement("span", createObj(ofArray([["style", {
        fontSize: 1.7 + "rem",
        marginBottom: 20,
        textAlign: "center",
    }], (elems = ["Bli med på Aksje.io - ", createElement("strong", {
        children: ["det er gratis!"],
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), createElement("a", {
        className: "pill-button",
        href: "/sign-up",
        children: "Registrer deg",
    }), createElement("span", createObj(ofArray([["style", {
        color: "var(--text-dim)",
    }], (elems_1 = ["Allerede registrert? ", createElement("a", {
        href: "/sign-in",
        style: {
            color: "var(--text-dim)",
            textDecorationLine: "underline",
        },
        children: "Logg inn",
    })], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_2))])])));
}

function latestContentHolder(children) {
    return createElement("div", {
        style: {
            width: 100 + "%",
            display: "flex",
            flexDirection: "column",
            padding: 10,
            flexGrow: 1,
        },
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    });
}

function latestListHolder(children) {
    return createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: ((2 + "vw") + " ") + (2 + "vw"),
            padding: 7,
        },
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    });
}

function latestListItemHolder(hide, children) {
    return createElement("div", createObj(toList(delay(() => append(hide ? singleton_1(["className", "hide-small-screens"]) : empty_1(), delay(() => append(singleton_1(["style", {
        display: "flex",
        flexDirection: "column",
        width: 200,
        userSelect: "none",
    }]), delay(() => singleton_1(["children", Interop_reactApi_1.Children.toArray(Array.from(children))])))))))));
}

function latestDate(x) {
    const children = singleton(createElement("span", {
        style: {
            fontSize: 0.75 + "rem",
        },
        children: utcDateToLocalDate(x),
    }));
    return createElement("div", {
        children: Interop_reactApi_1.Children.toArray(Array.from(children)),
    });
}

function latestLoadingIndicator() {
    const indicator = () => MuiHelpers_createElement(Skeleton, [["variant", "rectangular"], ["style", {
        height: 48,
        width: 100 + "%",
        maxWidth: 200,
    }]]);
    const xs = [indicator(), indicator(), indicator(), indicator(), indicator(), indicator(), indicator(), indicator(), indicator(), indicator()];
    return react.createElement(react.Fragment, {}, ...xs);
}

function drawLatestInsiderTrades(x) {
    return latestContentHolder([createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: tooltipHeader("Siste registrerte innsidehandler", "Denne oppsummeringen er AI-generert av ChatGPT fra børsmeldingen. Med forbehold om feil."),
    }), latestListHolder(toList(delay(() => {
        let xs_4;
        return (x != null) ? singleton_1((xs_4 = mapIndexed((i, e_1) => {
            let children_2, elems, children;
            const plusSign = (e_1.NumShares > 0) ? "+" : "";
            return latestListItemHolder(i > 4, [(children_2 = ofArray([createElement("a", createObj(ofArray([["href", `/stock/${e_1.Market}/${e_1.Ticker}`], (elems = [wrapTooltip(e_1.Name, createElement("span", {
                style: {
                    fontWeight: "bold",
                    userSelect: "none",
                },
                children: e_1.Ticker,
            }))], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), (children = ofArray([": ", createElement("span", {
                style: {
                    color: (e_1.NumShares > 0) ? "var(--buy)" : ((e_1.NumShares < 0) ? "var(--sell)" : "var(--text-dim)"),
                },
                children: `${plusSign}${toShortAmount(e_1.NumShares)} aksjer`,
            })]), createElement("span", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children)),
            }))]), createElement("div", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children_2)),
            })), latestDate(e_1.Date)]);
        }, sortByDescending((e) => e.Date, x, {
            Compare: comparePrimitives,
        })), react.createElement(react.Fragment, {}, ...xs_4))) : singleton_1(latestLoadingIndicator());
    })))]);
}

function drawLatestShort(x) {
    return latestContentHolder([createElement("div", {
        style: {
            display: "flex",
            justifyContent: "center",
        },
        children: tooltipHeader("Siste registrerte short", "Basert på short-posisjoner større eller lik 0.5% av utestående aksjer"),
    }), latestListHolder(toList(delay(() => {
        let xs_5;
        return (x != null) ? singleton_1((xs_5 = mapIndexed((i, e_1) => {
            let children, elems;
            return latestListItemHolder(i > 4, [(children = ofArray([createElement("a", {
                href: `/stock/${e_1.Market}/${e_1.Ticker}`,
                children: wrapTooltip(e_1.Name, createElement("span", {
                    style: {
                        fontWeight: "bold",
                        userSelect: "none",
                    },
                    children: e_1.Ticker,
                })),
            }), createElement("span", createObj(singleton((elems = toList(delay(() => append(singleton_1(": "), delay(() => append(singleton_1(createElement("span", {
                children: [`${e_1.NewPercent / 100}%`],
            })), delay(() => {
                const matchValue = e_1.ChangePercent;
                if (matchValue != null) {
                    const c = matchValue | 0;
                    return append(singleton_1(" ("), delay(() => append(singleton_1(createElement("span", {
                        style: {
                            marginRight: 3,
                        },
                        className: (c > 0) ? "fas fa-arrow-up" : "fas fa-arrow-down",
                    })), delay(() => singleton_1(createElement("span", {
                        children: [`${c / 100}%)`],
                    }))))));
                }
                else {
                    return empty_1();
                }
            })))))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))]))))]), createElement("div", {
                children: Interop_reactApi_1.Children.toArray(Array.from(children)),
            })), latestDate(e_1.Date)]);
        }, sortByDescending((e) => e.Date, x, {
            Compare: comparePrimitives,
        })), react.createElement(react.Fragment, {}, ...xs_5))) : singleton_1(latestLoadingIndicator());
    })))]);
}

function stats(latest, mostViewed, mostFavorite, mostShorted, explore, latestInsiderTrades, latestShort) {
    let elems_1, elems;
    return createElement("div", createObj(ofArray([["className", "landing-preview"], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 10,
        flexGrow: 1,
    }], (elems = toList(delay(() => append(singleton_1(drawMostShorted(mostShorted)), delay(() => append(singleton_1(drawMostFavorite(mostFavorite)), delay(() => append(singleton_1(drawExplore(explore)), delay(() => {
        const matchValue = latest;
        if (matchValue != null) {
            const x = matchValue;
            return (x.length > 0) ? singleton_1(drawReviews(x, "Siste analyser")) : empty_1();
        }
        else {
            return empty_1();
        }
    })))))))), ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), drawLatestInsiderTrades(latestInsiderTrades), drawLatestShort(latestShort)], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
}

export function LandingPage() {
    let elems_1, elems;
    const patternInput = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(undefined);
    const patternInput_6 = useFeliz_React__React_useState_Static_1505(undefined);
    useReact_useEffectOnce_3A5B6456(() => {
        const pr_5 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder_6, data_9, caseStrategy_18, extra_18;
            return ((decoder_6 = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_TopShortEntry_$reflection()), undefined, undefined), (data_9 = undefined, (caseStrategy_18 = undefined, (extra_18 = undefined, (() => {
                let properties_9;
                try {
                    const properties_3_2 = Helper_withProperties(empty(), (properties_9 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_9, empty()), 0)])]), defaultArg(map((data_1_3) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_18, extra_18)(data_1_3))]), properties_9), data_9), properties_9)));
                    const pr_4 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/highest-short?num=5`, properties_3_2).then((_arg_6) => {
                        let response_5, decoder_1_3;
                        return ((response_5 = _arg_6, (decoder_1_3 = defaultArg(decoder_6, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_TopShortEntry_$reflection()), unwrap(caseStrategy_18), unwrap(extra_18))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_5.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_5.text().then((_arg_7) => {
                            let matchValue_2;
                            return Promise.resolve((matchValue_2 = fromString(uncurry2(decoder_1_3), _arg_7), (matchValue_2.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_2.fields[0]])])) : (new FSharpResult$2(0, [matchValue_2.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_5])])))).then((_arg_1_3) => (Promise.resolve(_arg_1_3)))))))));
                    }))));
                    return pr_4.then(void 0, ((arg_2) => (new FSharpResult$2(1, [new FetchError(3, [arg_2])]))));
                }
                catch (exn_2) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_2])])))));
                }
            })()))))).then((_arg_8) => {
                const res_2 = _arg_8;
                if (res_2.tag === 0) {
                    patternInput_3[1](res_2.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_5.then();
        const pr_1 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder, data_1, caseStrategy_2, extra_2;
            return ((decoder = Auto_generateBoxedDecoder_Z6670B51(array_type(Analytics_AssetCount_$reflection()), undefined, undefined), (data_1 = undefined, (caseStrategy_2 = undefined, (extra_2 = undefined, (() => {
                let properties_2;
                try {
                    const properties_3 = Helper_withProperties(empty(), (properties_2 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_1, empty()), 0)])]), defaultArg(map((data_1_1) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_2, extra_2)(data_1_1))]), properties_2), data_1), properties_2)));
                    const pr = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/stats/most-favorite", properties_3).then((_arg) => {
                        let response_1, decoder_1_1;
                        return ((response_1 = _arg, (decoder_1_1 = defaultArg(decoder, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Analytics_AssetCount_$reflection()), unwrap(caseStrategy_2), unwrap(extra_2))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_1.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_1.text().then((_arg_1) => {
                            let matchValue;
                            return Promise.resolve((matchValue = fromString(uncurry2(decoder_1_1), _arg_1), (matchValue.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue.fields[0]])])) : (new FSharpResult$2(0, [matchValue.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_1])])))).then((_arg_1_1) => (Promise.resolve(_arg_1_1)))))))));
                    }))));
                    return pr.then(void 0, ((arg) => (new FSharpResult$2(1, [new FetchError(3, [arg])]))));
                }
                catch (exn) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn])])))));
                }
            })()))))).then((_arg_2) => {
                const res = _arg_2;
                if (res.tag === 0) {
                    patternInput[1](res.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_1.then();
        const pr_3 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder_3, data_5, caseStrategy_10, extra_10;
            return ((decoder_3 = Auto_generateBoxedDecoder_Z6670B51(array_type(Analytics_AssetMeta_$reflection()), undefined, undefined), (data_5 = undefined, (caseStrategy_10 = undefined, (extra_10 = undefined, (() => {
                let properties_6;
                try {
                    const properties_3_1 = Helper_withProperties(empty(), (properties_6 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_5, empty()), 0)])]), defaultArg(map((data_1_2) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_10, extra_10)(data_1_2))]), properties_6), data_5), properties_6)));
                    const pr_2 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/stats/explore", properties_3_1).then((_arg_3) => {
                        let response_3, decoder_1_2;
                        return ((response_3 = _arg_3, (decoder_1_2 = defaultArg(decoder_3, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Analytics_AssetMeta_$reflection()), unwrap(caseStrategy_10), unwrap(extra_10))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_3.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_3.text().then((_arg_4) => {
                            let matchValue_1;
                            return Promise.resolve((matchValue_1 = fromString(uncurry2(decoder_1_2), _arg_4), (matchValue_1.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_1.fields[0]])])) : (new FSharpResult$2(0, [matchValue_1.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_3])])))).then((_arg_1_2) => (Promise.resolve(_arg_1_2)))))))));
                    }))));
                    return pr_2.then(void 0, ((arg_1) => (new FSharpResult$2(1, [new FetchError(3, [arg_1])]))));
                }
                catch (exn_1) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_1])])))));
                }
            })()))))).then((_arg_5) => {
                const res_1 = _arg_5;
                if (res_1.tag === 0) {
                    patternInput_2[1](res_1.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_3.then();
        fetchLatestInsiderTrades((res_5) => {
            if (res_5.tag === 0) {
                patternInput_5[1](res_5.fields[0]);
            }
        });
        const pr_7 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder_9, data_13, caseStrategy_26, extra_26;
            return ((decoder_9 = Auto_generateBoxedDecoder_Z6670B51(array_type(DataServer_Short_ShortEvent_$reflection()), undefined, undefined), (data_13 = undefined, (caseStrategy_26 = undefined, (extra_26 = undefined, (() => {
                let properties_12;
                try {
                    const properties_3_3 = Helper_withProperties(empty(), (properties_12 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_13, empty()), 0)])]), defaultArg(map((data_1_4) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_26, extra_26)(data_1_4))]), properties_12), data_13), properties_12)));
                    const pr_6 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch(`${DataServerBaseUrl}/api/short/latest-short`, properties_3_3).then((_arg_9) => {
                        let response_7, decoder_1_4;
                        return ((response_7 = _arg_9, (decoder_1_4 = defaultArg(decoder_9, Auto_generateBoxedDecoderCached_Z6670B51(array_type(DataServer_Short_ShortEvent_$reflection()), unwrap(caseStrategy_26), unwrap(extra_26))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_7.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_7.text().then((_arg_10) => {
                            let matchValue_3;
                            return Promise.resolve((matchValue_3 = fromString(uncurry2(decoder_1_4), _arg_10), (matchValue_3.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_3.fields[0]])])) : (new FSharpResult$2(0, [matchValue_3.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_7])])))).then((_arg_1_4) => (Promise.resolve(_arg_1_4)))))))));
                    }))));
                    return pr_6.then(void 0, ((arg_3) => (new FSharpResult$2(1, [new FetchError(3, [arg_3])]))));
                }
                catch (exn_3) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_3])])))));
                }
            })()))))).then((_arg_11) => {
                const res_3 = _arg_11;
                if (res_3.tag === 0) {
                    patternInput_4[1](res_3.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_7.then();
        const pr_9 = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let decoder_12, data_17, caseStrategy_34, extra_34;
            return ((decoder_12 = Auto_generateBoxedDecoder_Z6670B51(array_type(Post_$reflection()), undefined, undefined), (data_17 = undefined, (caseStrategy_34 = undefined, (extra_34 = undefined, (() => {
                let properties_15;
                try {
                    const properties_3_4 = Helper_withProperties(empty(), (properties_15 = ofArray([new Types_RequestProperties(0, ["GET"]), new Types_RequestProperties(1, [keyValueList(Helper_withContentTypeJson(data_17, empty()), 0)])]), defaultArg(map((data_1_5) => cons(new Types_RequestProperties(2, [toString(0, Auto_generateBoxedEncoderCached_437914C6(obj_type, caseStrategy_34, extra_34)(data_1_5))]), properties_15), data_17), properties_15)));
                    const pr_8 = PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Helper_fetch("/api/asset/insider-trade/demo", properties_3_4).then((_arg_12) => {
                        let response_9, decoder_1_5;
                        return ((response_9 = _arg_12, (decoder_1_5 = defaultArg(decoder_12, Auto_generateBoxedDecoderCached_Z6670B51(array_type(Post_$reflection()), unwrap(caseStrategy_34), unwrap(extra_34))), PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (((response_9.ok) ? PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (response_9.text().then((_arg_13) => {
                            let matchValue_4;
                            return Promise.resolve((matchValue_4 = fromString(uncurry2(decoder_1_5), _arg_13), (matchValue_4.tag === 1) ? (new FSharpResult$2(1, [new FetchError(1, [matchValue_4.fields[0]])])) : (new FSharpResult$2(0, [matchValue_4.fields[0]]))));
                        })))) : (Promise.resolve(new FSharpResult$2(1, [new FetchError(2, [response_9])])))).then((_arg_1_5) => (Promise.resolve(_arg_1_5)))))))));
                    }))));
                    return pr_8.then(void 0, ((arg_4) => (new FSharpResult$2(1, [new FetchError(3, [arg_4])]))));
                }
                catch (exn_4) {
                    return PromiseBuilder__Run_212F1D4B_1(promise_1, PromiseBuilder__Delay_62FBFDE1_1(promise_1, () => (Promise.resolve(new FSharpResult$2(1, [new FetchError(0, [exn_4])])))));
                }
            })()))))).then((_arg_14) => {
                const res_4 = _arg_14;
                if (res_4.tag === 0) {
                    patternInput_6[1](res_4.fields[0]);
                    return Promise.resolve();
                }
                else {
                    return Promise.resolve();
                }
            });
        }));
        pr_9.then();
        fetchLatestReviews((res_6) => {
            if (res_6.tag === 0) {
                patternInput_1[1](res_6.fields[0]);
            }
        });
    });
    return createElement("div", createObj(ofArray([["style", {}], (elems_1 = [createElement("div", createObj(ofArray([["style", {
        display: "flex",
        flexWrap: "wrap",
    }], (elems = [stats(patternInput_1[0], undefined, patternInput[0], patternInput_3[0], patternInput_2[0], patternInput_5[0], patternInput_4[0]), signup()], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems))])]))), insiderTrades(patternInput_6[0]), header(), favorites(), joinNow()], ["children", Interop_reactApi_1.Children.toArray(Array.from(elems_1))])])));
}

