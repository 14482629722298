import { Record } from "../fable_modules/fable-library-js.4.17.0/Types.js";
import { int64_type, array_type, float64_type, anonRecord_type, int32_type, option_type, record_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.17.0/Reflection.js";

export class LoginRequest extends Record {
    constructor(Username, Password, Persist) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Persist = Persist;
    }
}

export function LoginRequest_$reflection() {
    return record_type("ApiDataTypes.LoginRequest", [], LoginRequest, () => [["Username", string_type], ["Password", string_type], ["Persist", bool_type]]);
}

export class RegisterRequest extends Record {
    constructor(Username, Password, Email) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.Email = Email;
    }
}

export function RegisterRequest_$reflection() {
    return record_type("ApiDataTypes.RegisterRequest", [], RegisterRequest, () => [["Username", string_type], ["Password", string_type], ["Email", string_type]]);
}

export class LoginResponse extends Record {
    constructor(Result, Message, BanUntil) {
        super();
        this.Result = Result;
        this.Message = Message;
        this.BanUntil = BanUntil;
    }
}

export function LoginResponse_$reflection() {
    return record_type("ApiDataTypes.LoginResponse", [], LoginResponse, () => [["Result", string_type], ["Message", string_type], ["BanUntil", option_type(string_type)]]);
}

export class OAuthAuthResponse extends Record {
    constructor(Result, Email, Token) {
        super();
        this.Result = Result;
        this.Email = Email;
        this.Token = Token;
    }
}

export function OAuthAuthResponse_$reflection() {
    return record_type("ApiDataTypes.OAuthAuthResponse", [], OAuthAuthResponse, () => [["Result", LoginResponse_$reflection()], ["Email", string_type], ["Token", string_type]]);
}

export class OAuthRegisterRequest extends Record {
    constructor(Token, Username) {
        super();
        this.Token = Token;
        this.Username = Username;
    }
}

export function OAuthRegisterRequest_$reflection() {
    return record_type("ApiDataTypes.OAuthRegisterRequest", [], OAuthRegisterRequest, () => [["Token", string_type], ["Username", string_type]]);
}

export class AppleAuthRequest extends Record {
    constructor(Code, IdToken, Email) {
        super();
        this.Code = Code;
        this.IdToken = IdToken;
        this.Email = Email;
    }
}

export function AppleAuthRequest_$reflection() {
    return record_type("ApiDataTypes.AppleAuthRequest", [], AppleAuthRequest, () => [["Code", string_type], ["IdToken", string_type], ["Email", option_type(string_type)]]);
}

export class Subscription extends Record {
    constructor(Expiry, AutoRenews, ProductId) {
        super();
        this.Expiry = Expiry;
        this.AutoRenews = AutoRenews;
        this.ProductId = ProductId;
    }
}

export function Subscription_$reflection() {
    return record_type("ApiDataTypes.Subscription", [], Subscription, () => [["Expiry", string_type], ["AutoRenews", bool_type], ["ProductId", string_type]]);
}

export class GenericResponse extends Record {
    constructor(Result, Message) {
        super();
        this.Result = Result;
        this.Message = Message;
    }
}

export function GenericResponse_$reflection() {
    return record_type("ApiDataTypes.GenericResponse", [], GenericResponse, () => [["Result", string_type], ["Message", string_type]]);
}

export class TextInput extends Record {
    constructor(Input) {
        super();
        this.Input = Input;
    }
}

export function TextInput_$reflection() {
    return record_type("ApiDataTypes.TextInput", [], TextInput, () => [["Input", string_type]]);
}

export class SearchEntry extends Record {
    constructor(Name, Extra, Type, TypeId, Link, Asset, Group) {
        super();
        this.Name = Name;
        this.Extra = Extra;
        this.Type = Type;
        this.TypeId = (TypeId | 0);
        this.Link = Link;
        this.Asset = Asset;
        this.Group = Group;
    }
}

export function SearchEntry_$reflection() {
    return record_type("ApiDataTypes.SearchEntry", [], SearchEntry, () => [["Name", string_type], ["Extra", string_type], ["Type", string_type], ["TypeId", int32_type], ["Link", string_type], ["Asset", option_type(anonRecord_type(["Market", string_type], ["Ticker", string_type]))], ["Group", option_type(anonRecord_type(["Id", int32_type]))]]);
}

export class Market extends Record {
    constructor(Id, Ticker, Name, Country, NumAssets) {
        super();
        this.Id = (Id | 0);
        this.Ticker = Ticker;
        this.Name = Name;
        this.Country = Country;
        this.NumAssets = (NumAssets | 0);
    }
}

export function Market_$reflection() {
    return record_type("ApiDataTypes.Market", [], Market, () => [["Id", int32_type], ["Ticker", string_type], ["Name", string_type], ["Country", string_type], ["NumAssets", int32_type]]);
}

export class Asset extends Record {
    constructor(Id, ISIN, Ticker, Name, Type, Market, Currency, Delisted) {
        super();
        this.Id = (Id | 0);
        this.ISIN = ISIN;
        this.Ticker = Ticker;
        this.Name = Name;
        this.Type = (Type | 0);
        this.Market = Market;
        this.Currency = Currency;
        this.Delisted = Delisted;
    }
}

export function Asset_$reflection() {
    return record_type("ApiDataTypes.Asset", [], Asset, () => [["Id", int32_type], ["ISIN", string_type], ["Ticker", string_type], ["Name", string_type], ["Type", int32_type], ["Market", Market_$reflection()], ["Currency", string_type], ["Delisted", option_type(string_type)]]);
}

export class AssetReviewForm extends Record {
    constructor(AssetId, Rating, PeriodEnd, Comment$, TargetPrice) {
        super();
        this.AssetId = (AssetId | 0);
        this.Rating = (Rating | 0);
        this.PeriodEnd = PeriodEnd;
        this.Comment = Comment$;
        this.TargetPrice = TargetPrice;
    }
}

export function AssetReviewForm_$reflection() {
    return record_type("ApiDataTypes.AssetReviewForm", [], AssetReviewForm, () => [["AssetId", int32_type], ["Rating", int32_type], ["PeriodEnd", string_type], ["Comment", string_type], ["TargetPrice", option_type(float64_type)]]);
}

export class AssetSummaryRequest extends Record {
    constructor(PeriodStart, PeriodEnd, SummaryMode) {
        super();
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.SummaryMode = (SummaryMode | 0);
    }
}

export function AssetSummaryRequest_$reflection() {
    return record_type("ApiDataTypes.AssetSummaryRequest", [], AssetSummaryRequest, () => [["PeriodStart", string_type], ["PeriodEnd", string_type], ["SummaryMode", int32_type]]);
}

export class AssetSummary extends Record {
    constructor(Rating, NumBuys, NumWeakBuys, NumHolds, NumWeakSells, NumSells, NumRatings, PeriodStart, PeriodEnd, AvgPrice, NumPriced) {
        super();
        this.Rating = Rating;
        this.NumBuys = (NumBuys | 0);
        this.NumWeakBuys = (NumWeakBuys | 0);
        this.NumHolds = (NumHolds | 0);
        this.NumWeakSells = (NumWeakSells | 0);
        this.NumSells = (NumSells | 0);
        this.NumRatings = (NumRatings | 0);
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
        this.AvgPrice = AvgPrice;
        this.NumPriced = (NumPriced | 0);
    }
}

export function AssetSummary_$reflection() {
    return record_type("ApiDataTypes.AssetSummary", [], AssetSummary, () => [["Rating", option_type(float64_type)], ["NumBuys", int32_type], ["NumWeakBuys", int32_type], ["NumHolds", int32_type], ["NumWeakSells", int32_type], ["NumSells", int32_type], ["NumRatings", int32_type], ["PeriodStart", option_type(string_type)], ["PeriodEnd", option_type(string_type)], ["AvgPrice", option_type(float64_type)], ["NumPriced", int32_type]]);
}

export class User extends Record {
    constructor(Id, Username, Official) {
        super();
        this.Id = (Id | 0);
        this.Username = Username;
        this.Official = Official;
    }
}

export function User_$reflection() {
    return record_type("ApiDataTypes.User", [], User, () => [["Id", int32_type], ["Username", string_type], ["Official", bool_type]]);
}

export class Reply extends Record {
    constructor(Id, ParentId, Content, Votes, NumVotes, User, IsMine, Published, VoteState, Removed) {
        super();
        this.Id = (Id | 0);
        this.ParentId = (ParentId | 0);
        this.Content = Content;
        this.Votes = (Votes | 0);
        this.NumVotes = (NumVotes | 0);
        this.User = User;
        this.IsMine = IsMine;
        this.Published = Published;
        this.VoteState = (VoteState | 0);
        this.Removed = Removed;
    }
}

export function Reply_$reflection() {
    return record_type("ApiDataTypes.Reply", [], Reply, () => [["Id", int32_type], ["ParentId", int32_type], ["Content", string_type], ["Votes", int32_type], ["NumVotes", int32_type], ["User", User_$reflection()], ["IsMine", bool_type], ["Published", string_type], ["VoteState", int32_type], ["Removed", bool_type]]);
}

export class ReplyForm extends Record {
    constructor(ParentId, Reply) {
        super();
        this.ParentId = (ParentId | 0);
        this.Reply = Reply;
    }
}

export function ReplyForm_$reflection() {
    return record_type("ApiDataTypes.ReplyForm", [], ReplyForm, () => [["ParentId", int32_type], ["Reply", string_type]]);
}

export class RepliesResponse extends Record {
    constructor(ParentId, Replies) {
        super();
        this.ParentId = (ParentId | 0);
        this.Replies = Replies;
    }
}

export function RepliesResponse_$reflection() {
    return record_type("ApiDataTypes.RepliesResponse", [], RepliesResponse, () => [["ParentId", int32_type], ["Replies", array_type(Reply_$reflection())]]);
}

export class LinkPreview extends Record {
    constructor(Title, Desc, Url, ImageUrl) {
        super();
        this.Title = Title;
        this.Desc = Desc;
        this.Url = Url;
        this.ImageUrl = ImageUrl;
    }
}

export function LinkPreview_$reflection() {
    return record_type("ApiDataTypes.LinkPreview", [], LinkPreview, () => [["Title", string_type], ["Desc", string_type], ["Url", string_type], ["ImageUrl", option_type(string_type)]]);
}

export class QuoteEntry extends Record {
    constructor(Close, Date$) {
        super();
        this.Close = Close;
        this.Date = Date$;
    }
}

export function QuoteEntry_$reflection() {
    return record_type("ApiDataTypes.QuoteEntry", [], QuoteEntry, () => [["Close", float64_type], ["Date", string_type]]);
}

export class ShareSplit extends Record {
    constructor(Date$, FactorBase, FactorChange) {
        super();
        this.Date = Date$;
        this.FactorBase = (FactorBase | 0);
        this.FactorChange = (FactorChange | 0);
    }
}

export function ShareSplit_$reflection() {
    return record_type("ApiDataTypes.ShareSplit", [], ShareSplit, () => [["Date", string_type], ["FactorBase", int32_type], ["FactorChange", int32_type]]);
}

export class PreviousReviewInfo extends Record {
    constructor(Id, Rating, TargetPrice, Published) {
        super();
        this.Id = (Id | 0);
        this.Rating = (Rating | 0);
        this.TargetPrice = TargetPrice;
        this.Published = Published;
    }
}

export function PreviousReviewInfo_$reflection() {
    return record_type("ApiDataTypes.PreviousReviewInfo", [], PreviousReviewInfo, () => [["Id", int32_type], ["Rating", int32_type], ["TargetPrice", option_type(float64_type)], ["Published", string_type]]);
}

export class PostReviewInfo extends Record {
    constructor(Currency, TargetPrice, Until, Expired, LastClose, ClosestClose, Previous) {
        super();
        this.Currency = Currency;
        this.TargetPrice = TargetPrice;
        this.Until = Until;
        this.Expired = Expired;
        this.LastClose = LastClose;
        this.ClosestClose = ClosestClose;
        this.Previous = Previous;
    }
}

export function PostReviewInfo_$reflection() {
    return record_type("ApiDataTypes.PostReviewInfo", [], PostReviewInfo, () => [["Currency", string_type], ["TargetPrice", option_type(float64_type)], ["Until", string_type], ["Expired", bool_type], ["LastClose", option_type(QuoteEntry_$reflection())], ["ClosestClose", option_type(QuoteEntry_$reflection())], ["Previous", option_type(PreviousReviewInfo_$reflection())]]);
}

export class ShortEventPosition extends Record {
    constructor(InstitutionId, Institution, PrevNumShares, NewNumShares) {
        super();
        this.InstitutionId = (InstitutionId | 0);
        this.Institution = Institution;
        this.PrevNumShares = (PrevNumShares | 0);
        this.NewNumShares = (NewNumShares | 0);
    }
}

export function ShortEventPosition_$reflection() {
    return record_type("ApiDataTypes.ShortEventPosition", [], ShortEventPosition, () => [["InstitutionId", int32_type], ["Institution", string_type], ["PrevNumShares", int32_type], ["NewNumShares", int32_type]]);
}

export class PostShortInfo extends Record {
    constructor(NewPercentage, PrevPercentage, PrevNumShares, NewNumShares, Positions) {
        super();
        this.NewPercentage = (NewPercentage | 0);
        this.PrevPercentage = PrevPercentage;
        this.PrevNumShares = PrevNumShares;
        this.NewNumShares = (NewNumShares | 0);
        this.Positions = Positions;
    }
}

export function PostShortInfo_$reflection() {
    return record_type("ApiDataTypes.PostShortInfo", [], PostShortInfo, () => [["NewPercentage", int32_type], ["PrevPercentage", option_type(int32_type)], ["PrevNumShares", option_type(int32_type)], ["NewNumShares", int32_type], ["Positions", array_type(ShortEventPosition_$reflection())]]);
}

export class InsiderTradeExchangeInfo extends Record {
    constructor(AvgPrice, Currency) {
        super();
        this.AvgPrice = AvgPrice;
        this.Currency = Currency;
    }
}

export function InsiderTradeExchangeInfo_$reflection() {
    return record_type("ApiDataTypes.InsiderTradeExchangeInfo", [], InsiderTradeExchangeInfo, () => [["AvgPrice", float64_type], ["Currency", string_type]]);
}

export class PostInsiderInfo extends Record {
    constructor(Insider, NumShares, AvgPrice, NewTotal, Controlled, AssetSharesThousands, SourceUrl, ExchangeInfo) {
        super();
        this.Insider = Insider;
        this.NumShares = (NumShares | 0);
        this.AvgPrice = AvgPrice;
        this.NewTotal = NewTotal;
        this.Controlled = Controlled;
        this.AssetSharesThousands = (AssetSharesThousands | 0);
        this.SourceUrl = SourceUrl;
        this.ExchangeInfo = ExchangeInfo;
    }
}

export function PostInsiderInfo_$reflection() {
    return record_type("ApiDataTypes.PostInsiderInfo", [], PostInsiderInfo, () => [["Insider", string_type], ["NumShares", int32_type], ["AvgPrice", option_type(float64_type)], ["NewTotal", option_type(int32_type)], ["Controlled", bool_type], ["AssetSharesThousands", int32_type], ["SourceUrl", string_type], ["ExchangeInfo", option_type(InsiderTradeExchangeInfo_$reflection())]]);
}

export class Post extends Record {
    constructor(Id, Type, Content, Rating, User, Votes, NumVotes, Published, VoteState, IsMine, Replies, NumReplies, Deleted, Updated, LinkPreview, ReviewInfo, AssetInfo, GroupInfo, ShortInfo, InsiderInfo) {
        super();
        this.Id = (Id | 0);
        this.Type = Type;
        this.Content = Content;
        this.Rating = (Rating | 0);
        this.User = User;
        this.Votes = (Votes | 0);
        this.NumVotes = (NumVotes | 0);
        this.Published = Published;
        this.VoteState = (VoteState | 0);
        this.IsMine = IsMine;
        this.Replies = Replies;
        this.NumReplies = (NumReplies | 0);
        this.Deleted = Deleted;
        this.Updated = Updated;
        this.LinkPreview = LinkPreview;
        this.ReviewInfo = ReviewInfo;
        this.AssetInfo = AssetInfo;
        this.GroupInfo = GroupInfo;
        this.ShortInfo = ShortInfo;
        this.InsiderInfo = InsiderInfo;
    }
}

export function Post_$reflection() {
    return record_type("ApiDataTypes.Post", [], Post, () => [["Id", int32_type], ["Type", string_type], ["Content", string_type], ["Rating", int32_type], ["User", User_$reflection()], ["Votes", int32_type], ["NumVotes", int32_type], ["Published", string_type], ["VoteState", int32_type], ["IsMine", bool_type], ["Replies", array_type(Reply_$reflection())], ["NumReplies", int32_type], ["Deleted", bool_type], ["Updated", option_type(anonRecord_type(["Count", int32_type], ["Updated", string_type]))], ["LinkPreview", option_type(LinkPreview_$reflection())], ["ReviewInfo", option_type(PostReviewInfo_$reflection())], ["AssetInfo", option_type(anonRecord_type(["Market", string_type], ["Name", string_type], ["Ticker", string_type], ["Type", int32_type]))], ["GroupInfo", option_type(anonRecord_type(["Id", int32_type], ["Name", string_type]))], ["ShortInfo", option_type(PostShortInfo_$reflection())], ["InsiderInfo", option_type(PostInsiderInfo_$reflection())]]);
}

export class ReportForm extends Record {
    constructor(Target, TargetId, Reason, Comment$) {
        super();
        this.Target = Target;
        this.TargetId = (TargetId | 0);
        this.Reason = (Reason | 0);
        this.Comment = Comment$;
    }
}

export function ReportForm_$reflection() {
    return record_type("ApiDataTypes.ReportForm", [], ReportForm, () => [["Target", string_type], ["TargetId", int32_type], ["Reason", int32_type], ["Comment", string_type]]);
}

export class UserInfo extends Record {
    constructor(UserId, Username, Active, Joined, IsMe, Official) {
        super();
        this.UserId = (UserId | 0);
        this.Username = Username;
        this.Active = Active;
        this.Joined = Joined;
        this.IsMe = IsMe;
        this.Official = Official;
    }
}

export function UserInfo_$reflection() {
    return record_type("ApiDataTypes.UserInfo", [], UserInfo, () => [["UserId", int32_type], ["Username", string_type], ["Active", bool_type], ["Joined", string_type], ["IsMe", bool_type], ["Official", bool_type]]);
}

export class UserStatistics extends Record {
    constructor(NumReviews, NumReplies, Score) {
        super();
        this.NumReviews = (NumReviews | 0);
        this.NumReplies = (NumReplies | 0);
        this.Score = (Score | 0);
    }
}

export function UserStatistics_$reflection() {
    return record_type("ApiDataTypes.UserStatistics", [], UserStatistics, () => [["NumReviews", int32_type], ["NumReplies", int32_type], ["Score", int32_type]]);
}

export class UserReview extends Record {
    constructor(ReviewId, Rating, PeriodEnd, Asset, AssetType, Market) {
        super();
        this.ReviewId = (ReviewId | 0);
        this.Rating = (Rating | 0);
        this.PeriodEnd = PeriodEnd;
        this.Asset = Asset;
        this.AssetType = (AssetType | 0);
        this.Market = Market;
    }
}

export function UserReview_$reflection() {
    return record_type("ApiDataTypes.UserReview", [], UserReview, () => [["ReviewId", int32_type], ["Rating", int32_type], ["PeriodEnd", string_type], ["Asset", string_type], ["AssetType", int32_type], ["Market", string_type]]);
}

export class ImageForm extends Record {
    constructor(Content, Filename) {
        super();
        this.Content = Content;
        this.Filename = Filename;
    }
}

export function ImageForm_$reflection() {
    return record_type("ApiDataTypes.ImageForm", [], ImageForm, () => [["Content", string_type], ["Filename", string_type]]);
}

export class Account_AccountInfo extends Record {
    constructor(Username, Email, EmailVerified, EmailRegistered, GoogleRegistered, AppleRegistered, Joined, Subscription) {
        super();
        this.Username = Username;
        this.Email = Email;
        this.EmailVerified = EmailVerified;
        this.EmailRegistered = EmailRegistered;
        this.GoogleRegistered = GoogleRegistered;
        this.AppleRegistered = AppleRegistered;
        this.Joined = Joined;
        this.Subscription = Subscription;
    }
}

export function Account_AccountInfo_$reflection() {
    return record_type("ApiDataTypes.Account.AccountInfo", [], Account_AccountInfo, () => [["Username", string_type], ["Email", string_type], ["EmailVerified", bool_type], ["EmailRegistered", bool_type], ["GoogleRegistered", bool_type], ["AppleRegistered", bool_type], ["Joined", string_type], ["Subscription", option_type(Subscription_$reflection())]]);
}

export class Account_PublishStatus extends Record {
    constructor(CanPublish, Reason) {
        super();
        this.CanPublish = CanPublish;
        this.Reason = Reason;
    }
}

export function Account_PublishStatus_$reflection() {
    return record_type("ApiDataTypes.Account.PublishStatus", [], Account_PublishStatus, () => [["CanPublish", bool_type], ["Reason", option_type(int32_type)]]);
}

export class Account_SubscriptionTransaction extends Record {
    constructor(ProductId, TransactionDate, Price, Currency, StoreId, Username, Refunded) {
        super();
        this.ProductId = ProductId;
        this.TransactionDate = TransactionDate;
        this.Price = (Price | 0);
        this.Currency = Currency;
        this.StoreId = (StoreId | 0);
        this.Username = Username;
        this.Refunded = Refunded;
    }
}

export function Account_SubscriptionTransaction_$reflection() {
    return record_type("ApiDataTypes.Account.SubscriptionTransaction", [], Account_SubscriptionTransaction, () => [["ProductId", string_type], ["TransactionDate", string_type], ["Price", int32_type], ["Currency", string_type], ["StoreId", int32_type], ["Username", string_type], ["Refunded", bool_type]]);
}

export class Account_SubscriptionPurchase extends Record {
    constructor(SubscriptionId, Status, StartDate, EndDate, Username, Transactions) {
        super();
        this.SubscriptionId = SubscriptionId;
        this.Status = (Status | 0);
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Username = Username;
        this.Transactions = Transactions;
    }
}

export function Account_SubscriptionPurchase_$reflection() {
    return record_type("ApiDataTypes.Account.SubscriptionPurchase", [], Account_SubscriptionPurchase, () => [["SubscriptionId", string_type], ["Status", int32_type], ["StartDate", string_type], ["EndDate", string_type], ["Username", string_type], ["Transactions", array_type(Account_SubscriptionTransaction_$reflection())]]);
}

export class Account_FavoriteCount extends Record {
    constructor(Favorites, HasSubscription, MaxFavorites) {
        super();
        this.Favorites = (Favorites | 0);
        this.HasSubscription = HasSubscription;
        this.MaxFavorites = (MaxFavorites | 0);
    }
}

export function Account_FavoriteCount_$reflection() {
    return record_type("ApiDataTypes.Account.FavoriteCount", [], Account_FavoriteCount, () => [["Favorites", int32_type], ["HasSubscription", bool_type], ["MaxFavorites", int32_type]]);
}

export class Analytics_ReviewEntry extends Record {
    constructor(ReviewId, AssetTicker, AssetType, Username, UserId, MarketTicker, Rating, Likes, Currency, TargetPrice) {
        super();
        this.ReviewId = (ReviewId | 0);
        this.AssetTicker = AssetTicker;
        this.AssetType = (AssetType | 0);
        this.Username = Username;
        this.UserId = (UserId | 0);
        this.MarketTicker = MarketTicker;
        this.Rating = (Rating | 0);
        this.Likes = (Likes | 0);
        this.Currency = Currency;
        this.TargetPrice = TargetPrice;
    }
}

export function Analytics_ReviewEntry_$reflection() {
    return record_type("ApiDataTypes.Analytics.ReviewEntry", [], Analytics_ReviewEntry, () => [["ReviewId", int32_type], ["AssetTicker", string_type], ["AssetType", int32_type], ["Username", string_type], ["UserId", int32_type], ["MarketTicker", string_type], ["Rating", int32_type], ["Likes", int32_type], ["Currency", string_type], ["TargetPrice", option_type(float64_type)]]);
}

export class Analytics_AssetCount extends Record {
    constructor(Name, AssetTicker, AssetType, MarketTicker, Num) {
        super();
        this.Name = Name;
        this.AssetTicker = AssetTicker;
        this.AssetType = (AssetType | 0);
        this.MarketTicker = MarketTicker;
        this.Num = (Num | 0);
    }
}

export function Analytics_AssetCount_$reflection() {
    return record_type("ApiDataTypes.Analytics.AssetCount", [], Analytics_AssetCount, () => [["Name", string_type], ["AssetTicker", string_type], ["AssetType", int32_type], ["MarketTicker", string_type], ["Num", int32_type]]);
}

export class Analytics_ReviewSummary extends Record {
    constructor(Buys, WeakBuys, Holds, WeakSells, Sells) {
        super();
        this.Buys = Buys;
        this.WeakBuys = WeakBuys;
        this.Holds = Holds;
        this.WeakSells = WeakSells;
        this.Sells = Sells;
    }
}

export function Analytics_ReviewSummary_$reflection() {
    return record_type("ApiDataTypes.Analytics.ReviewSummary", [], Analytics_ReviewSummary, () => [["Buys", array_type(Analytics_AssetCount_$reflection())], ["WeakBuys", array_type(Analytics_AssetCount_$reflection())], ["Holds", array_type(Analytics_AssetCount_$reflection())], ["WeakSells", array_type(Analytics_AssetCount_$reflection())], ["Sells", array_type(Analytics_AssetCount_$reflection())]]);
}

export class Analytics_RatingSpread extends Record {
    constructor(Buy, WeakBuy, Hold, WeakSell, Sell) {
        super();
        this.Buy = (Buy | 0);
        this.WeakBuy = (WeakBuy | 0);
        this.Hold = (Hold | 0);
        this.WeakSell = (WeakSell | 0);
        this.Sell = (Sell | 0);
    }
}

export function Analytics_RatingSpread_$reflection() {
    return record_type("ApiDataTypes.Analytics.RatingSpread", [], Analytics_RatingSpread, () => [["Buy", int32_type], ["WeakBuy", int32_type], ["Hold", int32_type], ["WeakSell", int32_type], ["Sell", int32_type]]);
}

export class Analytics_Average extends Record {
    constructor(Average, Num) {
        super();
        this.Average = (Average | 0);
        this.Num = (Num | 0);
    }
}

export function Analytics_Average_$reflection() {
    return record_type("ApiDataTypes.Analytics.Average", [], Analytics_Average, () => [["Average", int32_type], ["Num", int32_type]]);
}

export class Analytics_AssetMeta extends Record {
    constructor(Name, Ticker, Market, Type) {
        super();
        this.Name = Name;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Type = (Type | 0);
    }
}

export function Analytics_AssetMeta_$reflection() {
    return record_type("ApiDataTypes.Analytics.AssetMeta", [], Analytics_AssetMeta, () => [["Name", string_type], ["Ticker", string_type], ["Market", string_type], ["Type", int32_type]]);
}

export class Analytics_RatingTop extends Record {
    constructor(Buy, WeakBuy, Hold, WeakSell, Sell) {
        super();
        this.Buy = Buy;
        this.WeakBuy = WeakBuy;
        this.Hold = Hold;
        this.WeakSell = WeakSell;
        this.Sell = Sell;
    }
}

export function Analytics_RatingTop_$reflection() {
    return record_type("ApiDataTypes.Analytics.RatingTop", [], Analytics_RatingTop, () => [["Buy", option_type(Analytics_AssetMeta_$reflection())], ["WeakBuy", option_type(Analytics_AssetMeta_$reflection())], ["Hold", option_type(Analytics_AssetMeta_$reflection())], ["WeakSell", option_type(Analytics_AssetMeta_$reflection())], ["Sell", option_type(Analytics_AssetMeta_$reflection())]]);
}

export class Analytics_TopViewEntry extends Record {
    constructor(Ticker, Market, Views, Name) {
        super();
        this.Ticker = Ticker;
        this.Market = Market;
        this.Views = (Views | 0);
        this.Name = Name;
    }
}

export function Analytics_TopViewEntry_$reflection() {
    return record_type("ApiDataTypes.Analytics.TopViewEntry", [], Analytics_TopViewEntry, () => [["Ticker", string_type], ["Market", string_type], ["Views", int32_type], ["Name", option_type(string_type)]]);
}

export class Notifications_EntityInfo extends Record {
    constructor(Name, Id) {
        super();
        this.Name = Name;
        this.Id = (Id | 0);
    }
}

export function Notifications_EntityInfo_$reflection() {
    return record_type("ApiDataTypes.Notifications.EntityInfo", [], Notifications_EntityInfo, () => [["Name", string_type], ["Id", int32_type]]);
}

export class Notifications_Notification extends Record {
    constructor(Id, Notified, Read, Blob) {
        super();
        this.Id = (Id | 0);
        this.Notified = Notified;
        this.Read = Read;
        this.Blob = Blob;
    }
}

export function Notifications_Notification_$reflection() {
    return record_type("ApiDataTypes.Notifications.Notification", [], Notifications_Notification, () => [["Id", int32_type], ["Notified", string_type], ["Read", bool_type], ["Blob", string_type]]);
}

export class DataServer_Quote_QuoteSummaryEntry extends Record {
    constructor(Date$, Change, VolumeChange) {
        super();
        this.Date = Date$;
        this.Change = (Change | 0);
        this.VolumeChange = (VolumeChange | 0);
    }
}

export function DataServer_Quote_QuoteSummaryEntry_$reflection() {
    return record_type("ApiDataTypes.DataServer.Quote.QuoteSummaryEntry", [], DataServer_Quote_QuoteSummaryEntry, () => [["Date", string_type], ["Change", int32_type], ["VolumeChange", int32_type]]);
}

export class DataServer_Quote_PeriodAverage extends Record {
    constructor(Avg, PeriodStart, PeriodEnd) {
        super();
        this.Avg = Avg;
        this.PeriodStart = PeriodStart;
        this.PeriodEnd = PeriodEnd;
    }
}

export function DataServer_Quote_PeriodAverage_$reflection() {
    return record_type("ApiDataTypes.DataServer.Quote.PeriodAverage", [], DataServer_Quote_PeriodAverage, () => [["Avg", float64_type], ["PeriodStart", string_type], ["PeriodEnd", string_type]]);
}

export class DataServer_Quote_QuoteSummary extends Record {
    constructor(Last, Prev, Week, Month, Quarter, Year, AvgQuoteLastTen, AvgQuotePrevTen, AvgVolumeLastTen, AvgVolumePrevTen) {
        super();
        this.Last = Last;
        this.Prev = Prev;
        this.Week = Week;
        this.Month = Month;
        this.Quarter = Quarter;
        this.Year = Year;
        this.AvgQuoteLastTen = AvgQuoteLastTen;
        this.AvgQuotePrevTen = AvgQuotePrevTen;
        this.AvgVolumeLastTen = AvgVolumeLastTen;
        this.AvgVolumePrevTen = AvgVolumePrevTen;
    }
}

export function DataServer_Quote_QuoteSummary_$reflection() {
    return record_type("ApiDataTypes.DataServer.Quote.QuoteSummary", [], DataServer_Quote_QuoteSummary, () => [["Last", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["Prev", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["Week", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["Month", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["Quarter", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["Year", option_type(DataServer_Quote_QuoteSummaryEntry_$reflection())], ["AvgQuoteLastTen", option_type(DataServer_Quote_PeriodAverage_$reflection())], ["AvgQuotePrevTen", option_type(DataServer_Quote_PeriodAverage_$reflection())], ["AvgVolumeLastTen", option_type(DataServer_Quote_PeriodAverage_$reflection())], ["AvgVolumePrevTen", option_type(DataServer_Quote_PeriodAverage_$reflection())]]);
}

export class DataServer_Short_ShareActivityDate extends Record {
    constructor(Date$, Raises, Covers) {
        super();
        this.Date = Date$;
        this.Raises = (Raises | 0);
        this.Covers = (Covers | 0);
    }
}

export function DataServer_Short_ShareActivityDate_$reflection() {
    return record_type("ApiDataTypes.DataServer.Short.ShareActivityDate", [], DataServer_Short_ShareActivityDate, () => [["Date", string_type], ["Raises", int32_type], ["Covers", int32_type]]);
}

export class DataServer_Short_TopShortEntry extends Record {
    constructor(Ticker, Market, Name, Percentage, ShortDate) {
        super();
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.Percentage = (Percentage | 0);
        this.ShortDate = ShortDate;
    }
}

export function DataServer_Short_TopShortEntry_$reflection() {
    return record_type("ApiDataTypes.DataServer.Short.TopShortEntry", [], DataServer_Short_TopShortEntry, () => [["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["Percentage", int32_type], ["ShortDate", string_type]]);
}

export class DataServer_Short_LargestShortEntry extends Record {
    constructor(Ticker, Market, Name, NumShares, LastPrice, LastPriceDate, ShortDate) {
        super();
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.NumShares = (NumShares | 0);
        this.LastPrice = LastPrice;
        this.LastPriceDate = LastPriceDate;
        this.ShortDate = ShortDate;
    }
}

export function DataServer_Short_LargestShortEntry_$reflection() {
    return record_type("ApiDataTypes.DataServer.Short.LargestShortEntry", [], DataServer_Short_LargestShortEntry, () => [["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["NumShares", int32_type], ["LastPrice", float64_type], ["LastPriceDate", string_type], ["ShortDate", string_type]]);
}

export class DataServer_Short_ShortEvent extends Record {
    constructor(Date$, Ticker, Market, Name, ChangePercent, OldPercent, NewPercent) {
        super();
        this.Date = Date$;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.ChangePercent = ChangePercent;
        this.OldPercent = OldPercent;
        this.NewPercent = (NewPercent | 0);
    }
}

export function DataServer_Short_ShortEvent_$reflection() {
    return record_type("ApiDataTypes.DataServer.Short.ShortEvent", [], DataServer_Short_ShortEvent, () => [["Date", string_type], ["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["ChangePercent", option_type(int32_type)], ["OldPercent", option_type(int32_type)], ["NewPercent", int32_type]]);
}

export class DataServer_Short_ShortPosition extends Record {
    constructor(Date$, Ticker, Market, Name, Percent, NumShares, LastClose, LastCloseDate, Institution) {
        super();
        this.Date = Date$;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.Percent = (Percent | 0);
        this.NumShares = (NumShares | 0);
        this.LastClose = LastClose;
        this.LastCloseDate = LastCloseDate;
        this.Institution = Institution;
    }
}

export function DataServer_Short_ShortPosition_$reflection() {
    return record_type("ApiDataTypes.DataServer.Short.ShortPosition", [], DataServer_Short_ShortPosition, () => [["Date", string_type], ["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["Percent", int32_type], ["NumShares", int32_type], ["LastClose", float64_type], ["LastCloseDate", string_type], ["Institution", string_type]]);
}

export class DataServer_InsiderTrade_InsiderTradeEvent extends Record {
    constructor(Id, Date$, Ticker, Market, Name, NumShares) {
        super();
        this.Id = (Id | 0);
        this.Date = Date$;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.NumShares = (NumShares | 0);
    }
}

export function DataServer_InsiderTrade_InsiderTradeEvent_$reflection() {
    return record_type("ApiDataTypes.DataServer.InsiderTrade.InsiderTradeEvent", [], DataServer_InsiderTrade_InsiderTradeEvent, () => [["Id", int32_type], ["Date", string_type], ["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["NumShares", int32_type]]);
}

export class DataServer_InsiderTrade_InsiderTradeEventValuation extends Record {
    constructor(Date$, Ticker, Market, Name, NumShares, EstValueKs) {
        super();
        this.Date = Date$;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.NumShares = (NumShares | 0);
        this.EstValueKs = (EstValueKs | 0);
    }
}

export function DataServer_InsiderTrade_InsiderTradeEventValuation_$reflection() {
    return record_type("ApiDataTypes.DataServer.InsiderTrade.InsiderTradeEventValuation", [], DataServer_InsiderTrade_InsiderTradeEventValuation, () => [["Date", string_type], ["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["NumShares", int32_type], ["EstValueKs", int32_type]]);
}

export class DataServer_InsiderTrade_InsiderTradeEventPercentage extends Record {
    constructor(Date$, Ticker, Market, Name, NumShares, Percentage) {
        super();
        this.Date = Date$;
        this.Ticker = Ticker;
        this.Market = Market;
        this.Name = Name;
        this.NumShares = (NumShares | 0);
        this.Percentage = (Percentage | 0);
    }
}

export function DataServer_InsiderTrade_InsiderTradeEventPercentage_$reflection() {
    return record_type("ApiDataTypes.DataServer.InsiderTrade.InsiderTradeEventPercentage", [], DataServer_InsiderTrade_InsiderTradeEventPercentage, () => [["Date", string_type], ["Ticker", string_type], ["Market", string_type], ["Name", string_type], ["NumShares", int32_type], ["Percentage", int32_type]]);
}

export class DataServer_InsiderTrade_InsiderTradeSummary extends Record {
    constructor(NumTrades, PrevNumTrades, NumBought, PrevNumBought, NumSold, PrevNumSold, TotalEstimatedValue, PrevTotalEstimatedValue, TotalEstimatedValueBought, PrevTotalEstimatedValueBought, TotalEstimateValueSold, PrevTotalEstimatedValueSold, MostTrades, MostBoughtByNum, MostBoughtByValue, HighestTradeValue, MostSoldByNum, MostSoldByValue) {
        super();
        this.NumTrades = (NumTrades | 0);
        this.PrevNumTrades = (PrevNumTrades | 0);
        this.NumBought = (NumBought | 0);
        this.PrevNumBought = (PrevNumBought | 0);
        this.NumSold = (NumSold | 0);
        this.PrevNumSold = (PrevNumSold | 0);
        this.TotalEstimatedValue = (TotalEstimatedValue | 0);
        this.PrevTotalEstimatedValue = (PrevTotalEstimatedValue | 0);
        this.TotalEstimatedValueBought = (TotalEstimatedValueBought | 0);
        this.PrevTotalEstimatedValueBought = (PrevTotalEstimatedValueBought | 0);
        this.TotalEstimateValueSold = (TotalEstimateValueSold | 0);
        this.PrevTotalEstimatedValueSold = (PrevTotalEstimatedValueSold | 0);
        this.MostTrades = MostTrades;
        this.MostBoughtByNum = MostBoughtByNum;
        this.MostBoughtByValue = MostBoughtByValue;
        this.HighestTradeValue = HighestTradeValue;
        this.MostSoldByNum = MostSoldByNum;
        this.MostSoldByValue = MostSoldByValue;
    }
}

export function DataServer_InsiderTrade_InsiderTradeSummary_$reflection() {
    return record_type("ApiDataTypes.DataServer.InsiderTrade.InsiderTradeSummary", [], DataServer_InsiderTrade_InsiderTradeSummary, () => [["NumTrades", int32_type], ["PrevNumTrades", int32_type], ["NumBought", int32_type], ["PrevNumBought", int32_type], ["NumSold", int32_type], ["PrevNumSold", int32_type], ["TotalEstimatedValue", int32_type], ["PrevTotalEstimatedValue", int32_type], ["TotalEstimatedValueBought", int32_type], ["PrevTotalEstimatedValueBought", int32_type], ["TotalEstimateValueSold", int32_type], ["PrevTotalEstimatedValueSold", int32_type], ["MostTrades", option_type(Analytics_AssetMeta_$reflection())], ["MostBoughtByNum", option_type(Analytics_AssetMeta_$reflection())], ["MostBoughtByValue", option_type(Analytics_AssetMeta_$reflection())], ["HighestTradeValue", option_type(Analytics_AssetMeta_$reflection())], ["MostSoldByNum", option_type(Analytics_AssetMeta_$reflection())], ["MostSoldByValue", option_type(Analytics_AssetMeta_$reflection())]]);
}

export class DataServer_InsiderTrade_InsiderTradeAssetEntry extends Record {
    constructor(Date$, Insider, NumShares, AvgPrice, EstValueK) {
        super();
        this.Date = Date$;
        this.Insider = Insider;
        this.NumShares = (NumShares | 0);
        this.AvgPrice = AvgPrice;
        this.EstValueK = (EstValueK | 0);
    }
}

export function DataServer_InsiderTrade_InsiderTradeAssetEntry_$reflection() {
    return record_type("ApiDataTypes.DataServer.InsiderTrade.InsiderTradeAssetEntry", [], DataServer_InsiderTrade_InsiderTradeAssetEntry, () => [["Date", string_type], ["Insider", string_type], ["NumShares", int32_type], ["AvgPrice", option_type(float64_type)], ["EstValueK", int32_type]]);
}

export class Quote_Quote extends Record {
    constructor(Date$, Close, Volume) {
        super();
        this.Date = Date$;
        this.Close = Close;
        this.Volume = (Volume | 0);
    }
}

export function Quote_Quote_$reflection() {
    return record_type("ApiDataTypes.Quote.Quote", [], Quote_Quote, () => [["Date", string_type], ["Close", float64_type], ["Volume", int32_type]]);
}

export class Quote_MovingAverage extends Record {
    constructor(NumDays, LastQuote, MA) {
        super();
        this.NumDays = (NumDays | 0);
        this.LastQuote = LastQuote;
        this.MA = MA;
    }
}

export function Quote_MovingAverage_$reflection() {
    return record_type("ApiDataTypes.Quote.MovingAverage", [], Quote_MovingAverage, () => [["NumDays", int32_type], ["LastQuote", QuoteEntry_$reflection()], ["MA", float64_type]]);
}

export class Quote_MovingAverageSummary extends Record {
    constructor(Twenty, Fifty, Hundred, TwoHundred) {
        super();
        this.Twenty = Twenty;
        this.Fifty = Fifty;
        this.Hundred = Hundred;
        this.TwoHundred = TwoHundred;
    }
}

export function Quote_MovingAverageSummary_$reflection() {
    return record_type("ApiDataTypes.Quote.MovingAverageSummary", [], Quote_MovingAverageSummary, () => [["Twenty", option_type(Quote_MovingAverage_$reflection())], ["Fifty", option_type(Quote_MovingAverage_$reflection())], ["Hundred", option_type(Quote_MovingAverage_$reflection())], ["TwoHundred", option_type(Quote_MovingAverage_$reflection())]]);
}

export class Valuation_AssetKeyNumbers extends Record {
    constructor(NumShares, LastQuote, LastShort) {
        super();
        this.NumShares = NumShares;
        this.LastQuote = LastQuote;
        this.LastShort = LastShort;
    }
}

export function Valuation_AssetKeyNumbers_$reflection() {
    return record_type("ApiDataTypes.Valuation.AssetKeyNumbers", [], Valuation_AssetKeyNumbers, () => [["NumShares", int64_type], ["LastQuote", anonRecord_type(["Date", string_type], ["Price", float64_type])], ["LastShort", option_type(anonRecord_type(["Date", string_type], ["NumShares", int32_type], ["Percentage", float64_type]))]]);
}

export class Short_Institution extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function Short_Institution_$reflection() {
    return record_type("ApiDataTypes.Short.Institution", [], Short_Institution, () => [["Id", int32_type], ["Name", string_type]]);
}

export class Short_ShortTop extends Record {
    constructor(Institution, Shares, Percentage) {
        super();
        this.Institution = Institution;
        this.Shares = (Shares | 0);
        this.Percentage = (Percentage | 0);
    }
}

export function Short_ShortTop_$reflection() {
    return record_type("ApiDataTypes.Short.ShortTop", [], Short_ShortTop, () => [["Institution", Short_Institution_$reflection()], ["Shares", int32_type], ["Percentage", int32_type]]);
}

export class Short_ShortSummary extends Record {
    constructor(Shares, Percentage, Top) {
        super();
        this.Shares = (Shares | 0);
        this.Percentage = (Percentage | 0);
        this.Top = Top;
    }
}

export function Short_ShortSummary_$reflection() {
    return record_type("ApiDataTypes.Short.ShortSummary", [], Short_ShortSummary, () => [["Shares", int32_type], ["Percentage", int32_type], ["Top", array_type(Short_ShortTop_$reflection())]]);
}

export class Short_Event extends Record {
    constructor(Date$, Points, Shares) {
        super();
        this.Date = Date$;
        this.Points = (Points | 0);
        this.Shares = (Shares | 0);
    }
}

export function Short_Event_$reflection() {
    return record_type("ApiDataTypes.Short.Event", [], Short_Event, () => [["Date", string_type], ["Points", int32_type], ["Shares", int32_type]]);
}

export class Alerts_InsiderTradeAlert extends Record {
    constructor(Id, Asset, Registered, MinNumShares, MinEstValueK, Expiry, OnlyOnce, Push, Email) {
        super();
        this.Id = (Id | 0);
        this.Asset = Asset;
        this.Registered = Registered;
        this.MinNumShares = MinNumShares;
        this.MinEstValueK = MinEstValueK;
        this.Expiry = Expiry;
        this.OnlyOnce = OnlyOnce;
        this.Push = Push;
        this.Email = Email;
    }
}

export function Alerts_InsiderTradeAlert_$reflection() {
    return record_type("ApiDataTypes.Alerts.InsiderTradeAlert", [], Alerts_InsiderTradeAlert, () => [["Id", int32_type], ["Asset", Analytics_AssetMeta_$reflection()], ["Registered", string_type], ["MinNumShares", option_type(int64_type)], ["MinEstValueK", option_type(int32_type)], ["Expiry", option_type(string_type)], ["OnlyOnce", bool_type], ["Push", bool_type], ["Email", bool_type]]);
}

export class Alerts_InsiderTradeAlertForm extends Record {
    constructor(AssetId, MinNumShares, MinEstValueK, Expiry, OnlyOnce, Push, Email) {
        super();
        this.AssetId = (AssetId | 0);
        this.MinNumShares = MinNumShares;
        this.MinEstValueK = MinEstValueK;
        this.Expiry = Expiry;
        this.OnlyOnce = OnlyOnce;
        this.Push = Push;
        this.Email = Email;
    }
}

export function Alerts_InsiderTradeAlertForm_$reflection() {
    return record_type("ApiDataTypes.Alerts.InsiderTradeAlertForm", [], Alerts_InsiderTradeAlertForm, () => [["AssetId", int32_type], ["MinNumShares", option_type(int64_type)], ["MinEstValueK", option_type(int32_type)], ["Expiry", option_type(string_type)], ["OnlyOnce", bool_type], ["Push", bool_type], ["Email", bool_type]]);
}

export class Alerts_ShortAlert extends Record {
    constructor(Id, Asset, Registered, MinPercentagePoints, AbovePercentage, BelowPercentage, Expiry, OnlyOnce, Push, Email) {
        super();
        this.Id = (Id | 0);
        this.Asset = Asset;
        this.Registered = Registered;
        this.MinPercentagePoints = MinPercentagePoints;
        this.AbovePercentage = AbovePercentage;
        this.BelowPercentage = BelowPercentage;
        this.Expiry = Expiry;
        this.OnlyOnce = OnlyOnce;
        this.Push = Push;
        this.Email = Email;
    }
}

export function Alerts_ShortAlert_$reflection() {
    return record_type("ApiDataTypes.Alerts.ShortAlert", [], Alerts_ShortAlert, () => [["Id", int32_type], ["Asset", Analytics_AssetMeta_$reflection()], ["Registered", string_type], ["MinPercentagePoints", option_type(int32_type)], ["AbovePercentage", option_type(int32_type)], ["BelowPercentage", option_type(int32_type)], ["Expiry", option_type(string_type)], ["OnlyOnce", bool_type], ["Push", bool_type], ["Email", bool_type]]);
}

export class Alerts_ShortAlertForm extends Record {
    constructor(AssetId, MinPercentagePoints, AbovePercentage, BelowPercentage, Expiry, OnlyOnce, Push, Email) {
        super();
        this.AssetId = (AssetId | 0);
        this.MinPercentagePoints = MinPercentagePoints;
        this.AbovePercentage = AbovePercentage;
        this.BelowPercentage = BelowPercentage;
        this.Expiry = Expiry;
        this.OnlyOnce = OnlyOnce;
        this.Push = Push;
        this.Email = Email;
    }
}

export function Alerts_ShortAlertForm_$reflection() {
    return record_type("ApiDataTypes.Alerts.ShortAlertForm", [], Alerts_ShortAlertForm, () => [["AssetId", int32_type], ["MinPercentagePoints", option_type(int32_type)], ["AbovePercentage", option_type(int32_type)], ["BelowPercentage", option_type(int32_type)], ["Expiry", option_type(string_type)], ["OnlyOnce", bool_type], ["Push", bool_type], ["Email", bool_type]]);
}

export class Alerts_AlertCount extends Record {
    constructor(Alerts, HasSubscription, MaxAlerts) {
        super();
        this.Alerts = (Alerts | 0);
        this.HasSubscription = HasSubscription;
        this.MaxAlerts = (MaxAlerts | 0);
    }
}

export function Alerts_AlertCount_$reflection() {
    return record_type("ApiDataTypes.Alerts.AlertCount", [], Alerts_AlertCount, () => [["Alerts", int32_type], ["HasSubscription", bool_type], ["MaxAlerts", int32_type]]);
}

